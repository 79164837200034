<template>
  <svg preserveAspectRatio="none" viewBox="0 0 268 191" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M108.021 179.688C108.228 178.197 107.763 175.634 106.6 171.625C108.903 171.629 110.837 170.607 113.192 170.642C113.835 174.412 114.829 180.606 114.829 180.606L114.936 185.5L98.5518 185.626C98.5518 185.626 98.6888 185.696 103.519 182.634C103.876 182.406 107.821 181.128 108.021 179.688Z" fill="#F7A491"/>
    <path d="M115.013 186.76H98.1C97.473 186.704 97.3221 186.191 97.538 185.875C97.9838 185.227 101.283 183.665 103.501 182.629C107.393 183.041 114.229 181.433 114.844 180.529C115.91 183.524 115.362 186.683 115.013 186.76Z" fill="#3C8F7C"/>
    <path d="M128.43 179.688C128.639 178.197 128.99 176.331 128.474 171.362C130.775 171.367 132.967 170.935 135.32 170.97C135.32 174.849 135.241 180.606 135.241 180.606L135.347 185.5L118.961 185.626C118.961 185.626 119.098 185.696 123.928 182.634C124.285 182.406 128.23 181.128 128.43 179.688Z" fill="#F7A491"/>
    <path d="M135.423 186.76H118.51C117.885 186.704 117.732 186.191 117.948 185.875C118.396 185.227 121.693 183.665 123.913 182.629C127.802 183.041 134.641 181.433 135.254 180.529C136.322 183.524 135.772 186.683 135.423 186.76Z" fill="#3C8F7C"/>
    <path d="M116.523 37.7122L110.36 31.1868C110.36 31.1868 103.764 34.827 96.073 38.3045C93.96 34.6132 90.5072 22.4684 90.3237 21.8876C90.1356 21.3069 94.364 14.0102 93.4189 13.829C92.4715 13.6455 91.2455 17.7248 90.2448 17.3113C89.2463 16.8978 86.6758 7.43369 86.0744 7.95173C84.9483 8.91811 87.4258 13.836 86.8337 14.1961C86.2393 14.5538 84.7346 8.74157 83.5876 8.99013C82.4428 9.23637 85.5241 14.2797 85.1851 14.7652C84.8484 15.2507 81.6278 10.5187 80.9613 11.6152C80.6014 12.2099 83.9312 15.7339 83.2462 16.1288C82.5612 16.5237 79.5589 14.3029 79.2013 15.0231C78.8414 15.7409 82.0016 16.4912 82.8794 18.3102C83.7571 20.1314 85.3523 20.8399 85.8817 24.2804C86.4111 27.7185 87.8577 46.1123 91.5938 47.5897C94.8563 48.879 116.523 37.7122 116.523 37.7122Z" fill="#F7A491"/>
    <path d="M112.54 72.9037L117.54 75.7006L127.698 91.2859L109.529 134.197C109.529 134.197 111.577 140.65 112.243 143.875C112.94 147.248 114.651 176.2 114.651 176.2L106.9 176.33C106.9 176.33 99.537 145.508 98.29 135.505C96.7761 123.358 105.995 94.4243 105.995 94.4243C107.715 88.5215 111.421 76.3789 111.421 76.3789C111.421 76.3789 112.329 73.4194 112.54 72.9037Z" fill="#C49A50"/>
    <path d="M129.937 67.0499C129.937 67.0499 129.422 70.7156 131.488 74.8971C132.749 77.4478 134.925 79.4456 136.483 82.5701C139.434 88.4799 141.034 96.8754 139.929 102.272C139.929 102.272 133.829 125.639 134.073 134.179C134.161 137.329 135.238 143.002 135.928 146.839C136.536 150.229 136.743 175.808 136.743 175.808L128.086 175.545L121.457 134.286L118.657 97.6072L112.543 72.904L129.937 67.0499Z" fill="#F7CC7F"/>
    <path d="M110.694 12.5381C111.495 8.75159 112.32 4.94879 116.07 2.22154C119.105 0.0123253 122.367 -0.617218 125.824 0.641869C135.623 4.20541 130.352 12.0085 142.51 20.325C146.291 22.9082 147.723 23.8095 150.435 27.2523C154.23 32.0726 156.303 38.5376 154.397 43.862C150.452 54.8732 138.226 58.0535 131.831 55.7188C119.929 51.3747 110.014 36.2262 109.354 32.0935C108.649 27.6704 110.181 14.9773 110.694 12.5381Z" fill="#F7CC7F"/>
    <path d="M164.228 94.3712C163.434 93.7602 162.519 94.0877 159.804 92.9634C159.28 92.745 157.907 92.4198 157.294 92.3222C157.181 92.3036 157.085 92.2223 157.051 92.1108C156.117 89.0769 151.998 75.0504 151.443 70.9781C150.876 66.8153 147.468 49.2066 146.948 46.5305C146.913 46.3469 146.725 46.2378 146.548 46.2958L138.895 48.7676C138.739 48.8187 138.649 48.9836 138.693 49.1416C139.255 51.2346 142.766 64.3737 143.91 71.0501C144.823 76.3722 153.484 93.2119 153.688 94.3038L153.693 94.334C153.756 94.6615 154.048 94.8962 154.38 94.8822C156.347 94.8009 162.647 94.5454 163.006 94.5338C163.334 94.5222 164.476 94.5616 164.228 94.3712Z" fill="#F48C7F"/>
    <path d="M109.786 41.8103C107.03 43.0903 102.033 45.0115 102.033 45.0115L99.1904 35.9911C115.254 26.8221 113.993 28.3019 126.049 28.4831C135.681 28.6201 139.719 26.7756 148.011 49.2417C148.011 49.2417 141.697 52.0874 139.438 53.0004C138.465 50.2244 136.707 44.6839 135.683 47.8433C134.137 52.6125 133.001 57.4281 132.242 62.3878C131.759 65.3264 130.709 69.0874 131.262 72.0818C131.262 72.0818 124.702 73.4385 119.691 73.6592L119.647 73.6569C117.228 73.5941 114.748 73.3804 112.54 72.9042C112.54 72.9042 111.557 52.0619 111.033 51.1931C110.452 50.229 107.998 47.1185 110.085 42.4654C110.454 41.6431 110.355 41.5455 109.786 41.8103Z" fill="#2F4B7D"/>
    <path d="M124.54 19.2046C124.54 19.2046 125.309 25.4211 125.762 27.5792C126.233 29.8372 126.946 36.948 122.279 38.1002C117.614 39.2547 119.056 30.3645 118.979 28.0693C118.903 25.7742 117.579 17.769 117.579 17.769L124.54 19.2046Z" fill="#F7A491"/>
    <path d="M124.796 21.1883C124.747 21.2162 124.703 21.2487 124.654 21.2766C122.077 22.7587 119.831 22.5241 118.148 21.3695C117.846 19.3834 117.579 17.7689 117.579 17.7689L124.54 19.2045C124.54 19.2045 124.643 20.0338 124.796 21.1883Z" fill="#F47458"/>
    <path d="M123.138 3.02065C123.138 3.02065 125.874 3.7919 127.45 6.51684C129.027 9.24178 129.812 17.0658 124.009 20.404C118.206 23.7446 114.087 18.3946 114.261 13.3884C114.438 8.38457 115.826 1.76388 123.138 3.02065Z" fill="#F7A491"/>
    <path d="M112.98 11.241C112.915 12.5419 113.47 13.6268 114.22 13.6639C114.967 13.7034 115.627 12.679 115.69 11.3781C115.755 10.0772 115.2 8.99234 114.452 8.95517C113.704 8.91568 113.045 9.94014 112.98 11.241Z" fill="#F7A491"/>
    <path d="M122.316 2.8141C122.316 2.8141 125.446 10.9981 128.507 11.8716C131.57 12.7427 131.326 16.7686 131.326 16.7686C131.326 16.7686 131.718 7.46481 128.92 5.18359C126.124 2.90237 122.316 2.8141 122.316 2.8141Z" fill="#F7CC7F"/>
    <path d="M127.194 13.4707C126.723 14.7507 127.032 16.0492 127.886 16.3721C128.741 16.695 129.816 15.9191 130.287 14.6415C130.758 13.3615 130.45 12.0629 129.595 11.74C128.741 11.4171 127.666 12.1907 127.194 13.4707Z" fill="#F7A491"/>
    <path d="M123.62 45.1427L132.353 45.8164L132.681 41.5652L123.945 40.8892L123.62 45.1427Z" fill="#F4E5D4"/>
    <path d="M122.308 190.756H35.6643L13.2871 94.1639H122.308V190.756Z" fill="#F47458"/>
    <path d="M224.63 190.756H94.1191V94.1639H233.235L224.63 190.756Z" fill="#E25A44"/>
    <path d="M241.278 94.1641H0.917969V90.6702H241.278V94.1641Z" fill="#B75742"/>
    <path d="M59.1807 70.5813L60.0003 90.6709H69.4253L61.9554 71.6754L59.1807 70.5813Z" fill="#1D2F51"/>
    <path d="M85.4961 50.9719H43.1078C42.655 50.9719 42.2881 51.3389 42.2881 51.7919V84.3167C42.2881 84.7697 42.655 85.1367 43.1078 85.1367H85.4961C85.9488 85.1367 86.3157 84.7697 86.3157 84.3167V51.7919C86.3157 51.3389 85.9488 50.9719 85.4961 50.9719Z" fill="#2F4B7D"/>
    <path d="M55.5293 49.6367H41.1607C40.9053 49.6367 40.6963 49.43 40.6963 49.1721V42.8651C40.6963 42.6072 40.9053 42.4005 41.1607 42.4005H55.5293C55.787 42.4005 55.9937 42.6072 55.9937 42.8651V49.1721C55.9937 49.43 55.787 49.6367 55.5293 49.6367Z" fill="#2F4B7D"/>
    <path d="M49.2685 51.9443H48.04V47.4353H49.2685V51.9443Z" fill="#1D2F51"/>
    <path d="M117.622 15.9062L122.148 16.5544C122.148 16.5544 121.797 18.2595 119.798 18.0318C117.362 17.7554 117.622 15.9062 117.622 15.9062Z" fill="white"/>
    <path d="M224.507 162.611H225.501C225.828 159.264 226.471 155.568 227.618 151.698C231.949 137.069 230.054 135.179 237.366 126.033C244.676 116.889 245.219 97.9263 245.219 97.9263C245.219 97.9263 248.737 76.3894 245.219 66.909C241.699 57.4264 241.427 47.9461 232.899 37.9221C232.899 37.9221 231.273 51.3307 229.241 57.4264C227.212 63.522 225.282 69.3459 226.789 83.9741C228.294 98.6023 229.23 99.8219 229.573 103.615C229.919 107.407 229.378 123.394 230.189 126.033C231.002 128.672 225.452 146.959 225.046 154.813C224.923 157.175 224.704 159.865 224.507 162.611Z" fill="#215E4B"/>
    <path d="M227.622 162.611C229.034 159.354 231.516 156.121 235.863 154.179C247.236 149.098 254.908 149.212 260.188 137.023C265.469 124.832 265.469 108.171 267.296 104.717C267.296 104.717 262.422 103.906 257.548 112.64C252.674 121.377 249.368 127.88 246.553 132.958C243.739 138.038 239.678 136.617 236.225 140.475C233.332 143.711 230.659 151.238 229.645 155.057C229.315 156.297 228.834 157.491 228.203 158.609C227.627 159.624 226.951 160.86 226.545 161.74C226.454 161.938 226.364 162.235 226.275 162.611H227.622Z" fill="#3C8F7C"/>
    <path d="M195.533 104.833C194.72 115.263 201.354 114.45 201.625 121.087C201.897 127.724 210.561 138.967 214.575 146.959C216.663 151.115 217.434 157.468 217.694 162.611H220.919C219.67 149.595 214.392 140.538 211.239 133.278C207.175 123.933 206.567 119.665 210.02 112.35C213.472 105.037 208.394 86.1415 213.31 77.7181C218.223 69.2948 215.706 56.0721 214.575 50.9939C213.445 45.9134 214.689 42.459 215.706 37.1044C216.721 31.7498 214.575 23.9699 214.575 23.9699C214.392 30.6069 209.342 39.683 205.687 45.5069C202.032 51.3307 195.126 67.1785 195.939 74.359C196.752 81.5372 196.345 82.0785 196.345 82.0785C193.772 91.6959 196.345 94.4045 195.533 104.833Z" fill="#215E4B"/>
    <path d="M214.883 154.137C215.299 155.382 216.627 158.56 217.844 162.611H219.822C218.924 157.454 217.37 151.47 214.749 148.041C209.468 141.132 198.908 138.152 197.689 133.141C196.47 128.13 197.823 113.096 190.107 104.698C190.107 104.698 191.189 114.046 190.107 116.55C189.025 119.054 179.819 130.704 185.234 140.456C190.648 150.209 202.834 148.989 206.624 151.156C210.416 153.324 214.205 152.104 214.883 154.137Z" fill="#3C8F7C"/>
    <path d="M210.008 109.158C209.874 119.182 217.724 122.976 217.59 128.528C217.455 134.082 219.215 140.041 220.703 144.51C221.646 147.34 221.124 155.217 220.959 162.611H223.137C223.685 157.178 224.286 149.34 224.089 145.865C223.817 141.126 225.984 137.332 226.255 129.069C226.525 120.808 228.285 113.358 224.63 102.928C220.975 92.4997 221.651 80.0366 206.353 68.5236C206.353 68.5236 203.646 80.7149 205.675 87.3518C207.707 93.9887 210.143 99.1366 210.008 109.158Z" fill="#3C8F7C"/>
    <path d="M210.008 109.158C209.874 119.182 217.724 122.976 217.59 128.528C217.455 134.082 219.215 140.041 220.704 144.51C221.646 147.34 221.124 155.217 220.959 162.611H221.862C221.985 161.067 222.104 160.095 222.104 160.095L222.554 141.404C222.554 141.404 221.651 126.776 220.028 114.857C218.402 102.937 206.353 68.5236 206.353 68.5236C206.353 68.5236 203.646 80.7149 205.675 87.3518C207.707 93.9887 210.143 99.1366 210.008 109.158Z" fill="#327F6B"/>
    <path d="M225.507 154.056H204.537C203.105 154.056 202.046 155.399 202.378 156.795L210.022 189.046C210.259 190.047 211.153 190.756 212.181 190.756H224.044H225.507H237.37C238.399 190.756 239.293 190.047 239.532 189.046L247.176 156.795C247.506 155.399 246.45 154.056 245.015 154.056H225.507Z" fill="#F7CC7F"/>
  </svg>
</template>
