<template>
  <div v-if="showReferal" class="activity-header">
    <adminNav>
      <a href="#" :class="tabType === 'bonus' && 'router-link-active router-link-exact-active'" @click="tabType = 'bonus'">
        Бонусная программа
      </a>
      <a href="#" :class="tabType === 'ref' && 'router-link-active router-link-exact-active'" @click="tabType = 'ref'">
        Реферальная программа
      </a>
    </adminNav>
  </div>
  <form action="javascript:void(0)" ref="refferal">
    <div v-show="tabType === 'bonus'" class="layout-1">
      <div class="s-name bonuses-block">
        <div class="s-name-text">
          Название вида деятельности
        </div>
        <div class="s-name-input">
          <div class="input-wrapper">
            <div class="input-container" :class="errors.name && 'error-border'">
              <input class="input" v-model="formData.name" @input="errors.name = ''">
            </div>
            <span class="error" v-if="errors.name">{{ errors.name }}</span>
          </div>
        </div>
      </div>
      <div class="s-event bonuses-block">
        <div class="s-event-item">
          <div class="bonuses-header">
            <h2 class="title">Приветственный бонус</h2>
          </div>
          <div class="s-event-wrapper">
            <div class="s-event-left">
              <div class="s-event-hi-number s-event-first-input">
                <div class="input-wrapper">
                  <div class="input-container" :class="errors.greetBonus && 'error-border'">
                    <input class="input" v-model="formData.greetBonus" @input="errors.greetBonus = ''">
                  </div>
                  <span class="error" v-if="errors.greetBonus">{{ errors.greetBonus }}</span>
                </div>
              </div>
              <div class="s-event-hi-timing">
                <div class="s-event-hi-text">
                  бонусов, которые можно потратить
                </div>
                <div class="s-event-hi-select">
                  <Multiselect
                    v-model="formData.greetBonusDays"
                    :options="hi"
                    placeholder="Сразу"
                    :canClear="false"
                  />
                </div>
              </div>
              <div v-if="formData.greetBonusDays === 'Не сразу'" class="s-event-delete-timer">
                <div class="s-event-delete-preword">через</div>
                <div class="s-event-right-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.greetBonusDaysCanSpend && 'error-border'">
                      <input class="input" v-model="formData.greetBonusDaysCanSpend" @input="errors.greetBonusDaysCanSpend = ''">
                    </div>
                    <span class="error" v-if="errors.greetBonusDaysCanSpend">{{ errors.greetBonusDaysCanSpend }}</span>
                  </div>
                </div>
                <div class="s-event-delete-afterword">ч.</div>
              </div>
            </div>
            <div class="s-event-right" style="padding-left: 10px;">
              <!-- Одинаковый контейнер для всех элементов. -->
              <div class="s-event-delete-ask">
                <div class="s-event-delete-preword">Сгорают</div>
                <div class="s-event-right-select">
                  <Multiselect
                    v-model="formData.greetBonusBurn"
                    :options="deleteAsk"
                    placeholder="Да"
                    :canClear="false"
                  />
                </div>
              </div>
              <div v-if="formData.greetBonusBurn === 'Да'" class="s-event-delete-timer">
                <div class="s-event-delete-preword">через</div>
                <div class="s-event-right-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.greetBonusBurnDays && 'error-border'">
                      <input class="input" v-model="formData.greetBonusBurnDays" @input="errors.greetBonusBurnDays = ''">
                    </div>
                    <span class="error" v-if="errors.greetBonusBurnDays">{{ errors.greetBonusBurnDays }}</span>
                  </div>
                </div>
                <div class="s-event-delete-afterword">дн.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="s-event-item s-event-birthday">
          <div class="bonuses-header">
            <h2 class="title">На День рождения</h2>
          </div>
          <div class="s-event-wrapper">
            <div class="s-event-left">
              <div class="s-event-first-left">
                <div class="s-event-hi-number s-event-first-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.birthdayBonus && 'error-border'">
                      <input class="input" v-model="formData.birthdayBonus" @input="errors.birthdayBonus = ''">
                    </div>
                    <span class="error" v-if="errors.birthdayBonus">{{ errors.birthdayBonus }}</span>
                  </div>
                </div>
                <div class="s-event-first-aftertext">
                  бонусов
                </div>
              </div>
              <div class="s-event-hi-timing" style="margin-left: 85px;">
                <div class="s-event-hi-timing-preword">
                  Отправлять за
                </div>
                <div class="s-event-hi-timing-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.birthdayBonusDays && 'error-border'">
                      <input class="input" v-model="formData.birthdayBonusDays" @input="errors.birthdayBonusDays = ''">
                    </div>
                    <span class="error" v-if="errors.birthdayBonusDays">{{ errors.birthdayBonusDays }}</span>
                  </div>
                </div>
                <div class="s-event-hi-timing-afterword">
                  дн.
                </div>
              </div>
            </div>
            <div class="s-event-right">
              <!-- Одинаковый контейнер для всех элементов. -->
              <div class="s-event-delete-ask">
                <div class="s-event-delete-preword">Сгорают</div>
                <div class="s-event-right-select">
                  <Multiselect
                    v-model="formData.birthdayBonusBurn"
                    :options="deleteAsk"
                    placeholder="Да"
                    :canClear="false"
                  />
                </div>
              </div>
              <div v-if="formData.birthdayBonusBurn === 'Да'" class="s-event-delete-timer">
                <div class="s-event-delete-preword">через</div>
                <div class="s-event-right-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.birthdayBonusBurnDays && 'error-border'">
                      <input class="input" v-model="formData.birthdayBonusBurnDays" @input="errors.birthdayBonusBurnDays = ''">
                    </div>
                    <span class="error" v-if="errors.birthdayBonusBurnDays">{{ errors.birthdayBonusBurnDays }}</span>
                  </div>
                </div>
                <div class="s-event-delete-afterword">дн.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="s-event-item">
          <div class="bonuses-header">
            <h2 class="title">Когда долго нет покупок</h2>
          </div>
          <div class="s-event-wrapper">
            <div class="s-event-left">
              <div class="s-event-first-left">
                <div class="s-event-hi-number s-event-first-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.noShoppingBonus && 'error-border'">
                      <input class="input" v-model="formData.noShoppingBonus" @input="errors.noShoppingBonus = ''">
                    </div>
                    <span class="error" v-if="errors.noShoppingBonus">{{ errors.noShoppingBonus }}</span>
                  </div>
                </div>
                <div class="s-event-first-aftertext">
                  бонусов, когда нет покупок
                </div>
              </div>
              <div class="s-event-hi-timing" style="margin-left: 67px;">
                <div class="s-event-hi-timing-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.noShoppingBonusDays && 'error-border'">
                      <input class="input" v-model="formData.noShoppingBonusDays" @input="errors.noShoppingBonusDays = ''">
                    </div>
                    <span class="error" v-if="errors.noShoppingBonusDays">{{ errors.noShoppingBonusDays }}</span>
                  </div>
                </div>
                <div class="s-event-hi-timing-afterword">
                  дн.
                </div>
              </div>
            </div>
            <div class="s-event-right">
              <!-- Одинаковый контейнер для всех элементов. -->
              <div class="s-event-delete-ask">
                <div class="s-event-delete-preword">Сгорают</div>
                <div class="s-event-right-select">
                  <Multiselect
                    v-model="formData.noShoppingBonusBurn"
                    :options="deleteAsk"
                    placeholder="Да"
                    :canClear="false"
                  />
                </div>
              </div>
              <div v-if="formData.noShoppingBonusBurn === 'Да'" class="s-event-delete-timer">
                <div class="s-event-delete-preword">через</div>
                <div class="s-event-right-input">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.noShoppingBonusBurnDays && 'error-border'">
                      <input class="input" v-model="formData.noShoppingBonusBurnDays" @input="errors.noShoppingBonusBurnDays = ''">
                    </div>
                    <span class="error" v-if="errors.noShoppingBonusBurnDays">{{ errors.noShoppingBonusBurnDays }}</span>
                  </div>
                </div>
                <div class="s-event-delete-afterword">дн.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-desc bonuses-block">
        <p class="s-desc-text">
          После сохранения зайдите на вкладку <b><router-link class="link" to="/admin/messages/service">Сообщения / Сервисные сообщения</router-link> и посмотрите, какие сообщения будут отправляться клиентам. При необходимости скорректируйте сообщения.</b>
        </p>
      </div>
      <div class="s-status bonuses-block">
        <div class="bonuses-header">
          <h2 class="title">Статусы</h2>
          <div v-if="statusesData.data.length < 10" class="s-status-create">
            <button class="link" @click="beforeOpenNewStatus">Создать статус</button>
          </div>
        </div>
        <span class="error" v-if="isStatusError">Необходимо указать хотя бы один статус</span>
        <div class="s-status-table">
          <xTable v-bind="statusesData">
            <template v-slot="{ records }" >
              <td>
                <div class="table-action pr">
                  <button @click="editStatus(records.id)">
                    <icon name="edit" class="table-icon" />
                  </button>
                  <template v-if="typeof records.cantDelete === 'undefined' || !records.cantDelete">
                    <button class="custom-note-wrap" @click="deleteStatus(records.id)">
                      <icon name="delete" class="table-icon" />
                      <div class="custom-note">
                        Удаление статуса никак не повлияет на ранее созданные компании с таким видом деятельности
                      </div>
                    </button>
                  </template>
                </div>
              </td>
            </template>
          </xTable>
        </div>
      </div>
      <div class="s-specs bonuses-block">
        <div class="s-specs-wrapper">
          <div class="s-specs-item">
            <div class="s-specs-preword">Бонусы доступны после покупки</div>
            <div class="s-specs-changer">
              <Multiselect
                v-model="formData.bonusAfterBuy"
                :options="hiBonusesPeriod"
                placeholder="Сразу"
                :canClear="false"
              />
            </div>
            <template v-if="formData.bonusAfterBuy === 'Не сразу'">
              <div class="s-specs-afterword">через</div>
              <div class="s-specs-changer-small">
                <div class="input-wrapper">
                  <div class="input-container" :class="errors.bonusAfterBuyHours && 'error-border'">
                    <input class="input" v-model="formData.bonusAfterBuyHours" @input="errors.bonusAfterBuyHours = ''">
                  </div>
                  <span class="error" v-if="errors.bonusAfterBuyHours">{{ errors.bonusAfterBuyHours }}</span>
                </div>
              </div>
              <div class="s-specs-afterword">часа</div>
            </template>
          </div>
          <div class="s-specs-item">
            <div class="s-specs-preword">Какой процент счета можно оплатить бонусами</div>
            <div class="s-specs-changer">
              <div class="input-wrapper">
                <div class="input-container" :class="errors.percentCanUse && 'error-border'">
                  <input class="input" v-model="formData.percentCanUse" @blur="e => validatePercent('percentCanUse', e.target.value)">
                  <div class="float">%</div>
                </div>
                <span class="error" v-if="errors.percentCanUse">{{ errors.percentCanUse }}</span>
              </div>
            </div>
          </div>
          <div class="s-specs-item">
            <div class="s-specs-preword">Начислять бонусы по номеру телефона</div>
            <div class="s-specs-changer">
              <Multiselect
                v-model="formData.usePhone"
                :options="bonusesMob"
                placeholder="Да"
                :canClear="false"
              />
            </div>
          </div>
          <div class="s-specs-item">
            <div class="s-specs-preword">Бонусы на счете сгорают</div>
            <div class="s-specs-changer">
              <Multiselect
                v-model="formData.bonusBurn"
                :options="bonusesDelete"
                placeholder="Да"
                :canClear="false"
              />
            </div>
            <template v-if="formData.bonusBurn === 'Да'">
              <div class="s-specs-afterword">через</div>
              <div class="s-specs-changer-small">
                <div class="input-wrapper">
                  <div class="input-container" :class="errors.bonusBurnDays && 'error-border'">
                    <input class="input" v-model="formData.bonusBurnDays" @input="errors.bonusBurnDays = ''">
                  </div>
                  <span class="error" v-if="errors.bonusBurnDays">{{ errors.bonusBurnDays }}</span>
                </div>
              </div>
              <div class="s-specs-afterword">дней без покупок</div>
            </template>
          </div>
        </div>
        <p class="s-desc-text">
          После сохранения зайдите на вкладку <b><router-link class="link" to="/admin/messages/service">Сообщения / Сервисные сообщения</router-link> и посмотрите, какие сообщения будут отправляться клиентам. При необходимости скорректируйте сообщения.</b>
        </p>
      </div>
      <div class="s-time bonuses-block">
        <div class="s-time-desc">Давность последней покупки, после которой клиента считать потерянным</div>
        <div class="s-time-input">
          <div class="input-wrapper">
            <div class="input-container" :class="errors.clientLostDays && 'error-border'">
              <input class="input" v-model="formData.clientLostDays" @input="errors.clientLostDays = ''">
            </div>
            <span class="error" v-if="errors.clientLostDays">{{ errors.clientLostDays }}</span>
          </div>
        </div>
        <div class="s-time-afterword">дней</div>
      </div>
      <div class="s-attention bonuses-block">
        <div class="bonuses-header">
          <h2 class="title">Подозрительная активность</h2>
        </div>
        <div class="s-attention-content">
          <div class="columns-2">
            <div class="columns-2-left">
              <div class="x-input-item">
                <div class="input-name">Покупок в день</div>
                <div class="input-content">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.suspicionPurchaseDay && 'error-border'">
                      <input class="input" v-model="formData.suspicionPurchaseDay" @input="errors.suspicionPurchaseDay = ''">
                    </div>
                    <span class="error" v-if="errors.suspicionPurchaseDay">{{ errors.suspicionPurchaseDay }}</span>
                  </div>
                </div>
                <div class="s-attention-afterword">и более</div>
              </div>
              <div class="x-input-item">
                <div class="input-name">Покупок за 7 дней</div>
                <div class="input-content">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.suspicionPurchaseWeek && 'error-border'">
                      <input class="input" v-model="formData.suspicionPurchaseWeek" @input="errors.suspicionPurchaseWeek = ''">
                    </div>
                  <span class="error" v-if="errors.suspicionPurchaseWeek">{{ errors.suspicionPurchaseWeek }}</span>
                  </div>
                </div>
                <div class="s-attention-afterword">и более</div>
              </div>
              <div class="x-input-item">
                <div class="input-name">Покупок за 30 дней</div>
                <div class="input-content">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.suspicionPurchaseMonth && 'error-border'">
                      <input class="input" v-model="formData.suspicionPurchaseMonth" @input="errors.suspicionPurchaseMonth = ''">
                    </div>
                    <span class="error" v-if="errors.suspicionPurchaseMonth">{{ errors.suspicionPurchaseMonth }}</span>
                  </div>
                </div>
                <div class="s-attention-afterword">и более</div>
              </div>
            </div>
            <div class="columns-2-right">
              <div class="x-input-item">
                <div class="input-name">Рекомендаций в день</div>
                <div class="input-content">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.suspicionRecommendDay && 'error-border'">
                      <input class="input" v-model="formData.suspicionRecommendDay" @blur="e => validateCount('suspicionRecommendDay', e.target.value)">
                    </div>
                    <span class="error" v-if="errors.suspicionRecommendDay">{{ errors.suspicionRecommendDay }}</span>
                  </div>
                </div>
                <div class="s-attention-afterword">и более</div>
              </div>
              <div class="x-input-item">
                <div class="input-name">Рекомендаций за 7 дней</div>
                <div class="input-content">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.suspicionRecommendWeek && 'error-border'">
                      <input class="input" v-model="formData.suspicionRecommendWeek" @blur="e => validateCount('suspicionRecommendWeek', e.target.value)">
                    </div>
                    <span class="error" v-if="errors.suspicionRecommendWeek">{{ errors.suspicionRecommendWeek }}</span>
                  </div>
                </div>
                <div class="s-attention-afterword">и более</div>
              </div>
              <div class="x-input-item">
                <div class="input-name">Рекомендаций за 30 дней</div>
                <div class="input-content">
                  <div class="input-wrapper">
                    <div class="input-container" :class="errors.suspicionRecommendMonth && 'error-border'">
                      <input class="input" v-model="formData.suspicionRecommendMonth" @blur="e => validateCount('suspicionRecommendMonth', e.target.value)">
                    </div>
                    <span class="error" v-if="errors.suspicionRecommendMonth">{{ errors.suspicionRecommendMonth }}</span>
                  </div>
                </div>
                <div class="s-attention-afterword">и более</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="referrals-footer">
        <div class="referrals-footer-button referrals-footer-button-duo">
          <xButton additionalClass="x-button-mr" @click="nextTabAction">
            Далее
          </xButton>
          <xButton additionalClass="x-button-white" @click="onGoBack">
            Отменить
          </xButton>
        </div>
      </div>
    </div>
    <div v-if="showReferal" v-show="tabType === 'ref'" class="layout-1">
      <div class="recommendation">
        <h2 class="title">Бонусы рекомендателю за приведенного клиента</h2>
        <div class="recommendation-input">
          <div class="x-input-item">
            <div class="input-name">Бонус за рекомендацию</div>
            <div class="input-content">
              <div class="input-wrapper">
                <div class="input-container" :class="errors.recommendBonus && 'error-border'">
                  <numberFormat
                    class="input"
                    v-model:value="formData.recommendBonus"
                    :options="formatSum"
                    @blur="e => validateSum('recommendBonus', e.target.value, 0)"
                  ></numberFormat>
                  <div class="float">р.</div>
                </div>
                <span class="error" v-if="errors.recommendBonus">{{ errors.recommendBonus }}</span>
              </div>
            </div>
          </div>
          <p class="desc">
            Вознаграждение клиенту (фиксированная сумма) за покупки друга по его рекомендации
          </p>
        </div>
        <div class="changer">
          <div class="changer-item">
            <label class="label">
              <input class="radio" type="radio" name="recommendBonusAward" value="0" v-model="formData.recommendBonusAward"/>
              <span class="checkmark"></span>
              <span class="label-text">
              Начислять за ПЕРВУЮ покупку
            </span>
            </label>
          </div>
          <div class="changer-item">
            <label class="label">
              <input class="radio" type="radio" name="recommendBonusAward" value="1" v-model="formData.recommendBonusAward"/>
              <span class="checkmark"></span>
              <span class="label-text">
              Начислять за КАЖДУЮ покупку
            </span>
            </label>
          </div>
        </div>
      </div>
      <div class="system">
        <div class="title">Реферальная система</div>
        <p class="desc">Вознаграждение клиенту в процентах от покупки друга и его друзей</p>
        <div class="system-level">
          <div class="system-level-item">
            <div class="x-input-item">
              <div class="input-name">1 уровень</div>
              <div class="input-content">
                <div class="input-wrapper">
                  <div class="input-container" :class="errors.referralLevel1 && 'error-border'">
                    <input class="input" v-model="formData.referralLevel1" @blur="e => validatePercent('referralLevel1', e.target.value, 0)">
                    <div class="float">%</div>
                  </div>
                  <span class="error" v-if="errors.referralLevel1">{{ errors.referralLevel1 }}</span>
                </div>
              </div>
            </div>
            <p class="system-level-desc">
              Иван порекомендовал Николаю. Это процент с покупок Николая
            </p>
          </div>
          <div class="system-level-item">
            <div class="x-input-item">
              <div class="input-name">2 уровень</div>
              <div class="input-content">
                <div class="input-wrapper">
                  <div class="input-container" :class="errors.referralLevel2 && 'error-border'">
                    <input class="input" v-model="formData.referralLevel2" @blur="e => validatePercent('referralLevel2', e.target.value, 0)">
                    <div class="float">%</div>
                  </div>
                  <span class="error" v-if="errors.referralLevel2">{{ errors.referralLevel2 }}</span>
                </div>
              </div>
            </div>
            <p class="system-level-desc">
              Потом Николай порекомендовал Сергею. Это процент Ивану с покупок Сергея
            </p>
          </div>
          <div class="system-level-item">
            <div class="x-input-item">
              <div class="input-name">3 уровень</div>
              <div class="input-content">
                <div class="input-wrapper">
                  <div class="input-container" :class="errors.referralLevel3 && 'error-border'">
                    <input class="input" v-model="formData.referralLevel3" @blur="e => validatePercent('referralLevel3', e.target.value, 0)">
                    <div class="float">%</div>
                  </div>
                  <span class="error" v-if="errors.referralLevel3">{{ errors.referralLevel3 }}</span>
                </div>
              </div>
            </div>
            <p class="system-level-desc">
              Потом Сергей порекомендовал Петру. Это процент Ивану с покупок Петра
            </p>
          </div>
        </div>
        <div class="changer">
          <div class="changer-item">
            <label class="label">
              <input class="radio" type="radio" value="0" name="referralAward" v-model="formData.referralAward"/>
              <span class="checkmark"></span>
              <span class="label-text">
              Начислять за ПЕРВУЮ покупку
            </span>
            </label>
          </div>
          <div class="changer-item">
            <label class="label">
              <input class="radio" type="radio" value="1" name="referralAward" v-model="formData.referralAward"/>
              <span class="checkmark"></span>
              <span class="label-text">
              Начислять за КАЖДУЮ покупку
            </span>
            </label>
          </div>
        </div>
      </div>
      <span class="error" v-if="isHaveErrors || formError">{{ formError ? formError : 'Исправьте все ошибки, чтобы сохранить' }}</span>
      <div class="referrals-footer">
        <div class="referrals-footer-button referrals-footer-button-duo">
          <xButton additionalClass="x-button-mr" @click="onSendForm">
            Сохранить
          </xButton>
          <xButton additionalClass="x-button-white" @click="onGoBack">
            Отменить
          </xButton>
        </div>
      </div>
    </div>
  </form>
  <pop-up :open="popUp" @close="closeStatusPopup" :privateView="true">
    <h2 class="x-pop-title">{{ isEditStatus ? 'Редактирование статуса' : 'Создание статуса' }}</h2>
    <div class="status-description" v-if="lessStatus">
      Предыдущий статус {{ lessStatus.bonusesName }} - {{ lessStatus.bonusRate }};
      <br>
      <template v-if="lessStatus.bonusSum">за покупки от {{ lessStatus.bonusSum }}</template>
      <template v-if="lessStatus.bonusSum && lessStatus.bonusSum">&nbsp;или&nbsp;</template>
      <template v-if="lessStatus.bonusRefferalNumber">за эффективные рекомендации от {{ lessStatus.bonusRefferalNumber }}</template>.
    </div>
    <div class="x-input-item">
      <div class="input-name">Название статуса</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="errors.bonusesName && 'error-border'">
            <input class="input" v-model="newStatus.bonusesName" maxlength="40" @input="errors.bonusesName = ''">
          </div>
          <span class="error" v-if="errors.bonusesName">{{ errors.bonusesName }}</span>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Размер бонуса с покупки в процентах</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="errors.bonusRate && 'error-border'">
            <input class="input" v-model="newStatus.bonusRate" @blur="e => validatePercent('bonusRate', e.target.value)">
            <div class="float">%</div>
          </div>
          <span class="error" v-if="errors.bonusRate">{{ errors.bonusRate }}</span>
        </div>
      </div>
    </div>
    <p class="x-pop-desc__bold">
      Присваивается клиенту автоматически при выполнении им <span class="x-pop-desc__bold-orange">любого из условий:</span>
    </p>
    <div class="x-input-item" :class="(typeof newStatus.id !== 'undefined' ? newStatus.id : false) === smallestStatusId && 'disabled-status-row'">
      <div class="input-name">Сумма собственных покупок</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="errors.bonusSum && 'error-border'">
            <numberFormat
              class="input"
              v-model:value="newStatus.bonusSum"
              :options="formatSum"
              @blur="e => validateSum('bonusSum', e.target.value)"
            ></numberFormat>
            <div class="float">р.</div>
          </div>
          <span class="error" v-if="errors.bonusSum">{{ errors.bonusSum }}</span>
        </div>
      </div>
    </div>
    <div class="x-input-item" :class="(typeof newStatus.id !== 'undefined' ? newStatus.id : false) === smallestStatusId && 'disabled-status-row'">
      <div class="input-name">Кол-во эффективных рекомендаций</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="errors.bonusRefferalNumber && 'error-border'">
            <input class="input" v-model="newStatus.bonusRefferalNumber" @blur="e => validateCount('bonusRefferalNumber', e.target.value)">
          </div>
          <span class="error" v-if="errors.bonusRefferalNumber">{{ errors.bonusRefferalNumber }}</span>
        </div>
      </div>
    </div>
    <div class="status-description">Если статус присваивается только за собственные покупки, оставьте поле "Количество эффективных рекомендаций" пустым. Если статус присваивается только за эффективные рекомендации, оставьте поле "Сумма собственных покупок" пустым</div>
    <span class="error" v-if="statusError">{{ statusError }}</span>
    <xButton additionalClass="mt-20" @onClick="saveStatus">Сохранить</xButton>
  </pop-up>
</template>

<script>
import numberFormat from '@/components/numberFormat'
import debounce from 'lodash.debounce'
import xTable from '@/components/xTable'
import adminNav from '@/components/adminNav'
import xButton from '@/components/xButton'
import popUp from '@/components/popUp'

const validatePercent = debounce((val, min, successCall, errorCall) => {
  const minVal = min !== false ? min : 0.01

  const toFloat = parseFloat(val.toString().replace(',', '.'))
  if (isNaN(toFloat)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toFloat < minVal) {
    return errorCall('Значение не может быть меньше ' + minVal)
  }
  if (toFloat > 100.00) {
    return errorCall('Значение не может быть больше 100')
  }

  const splitted = toFloat.toString().split('.')
  if (splitted.length === 2) {
    if (splitted[1].length >= 2) {
      splitted[1] = splitted[1].slice(0, 2)
    } else {
      splitted[1] = splitted[1] + '0'
    }
  }
  successCall(splitted.join('.'))
}, 0)
const validateSum = debounce((val, min, successCall, errorCall) => {
  const minVal = min !== false ? min : 1

  const toInt = parseInt(val.replace(/\D/gmu, ''))
  if (isNaN(toInt)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toInt < minVal) {
    return errorCall('Значение не может быть меньше ' + minVal)
  }
  if (toInt > 1000000000) {
    return errorCall('Значение не может быть больше 1 000 000 000')
  }

  successCall(toInt)
}, 0)
const validateCount = debounce((val, successCall, errorCall) => {
  const toInt = parseInt(val)
  if (isNaN(toInt)) {
    return errorCall('Значение введено с ошибкой')
  }

  if (toInt < 1) {
    return errorCall('Значение не может быть меньше 1')
  }
  if (toInt > 1000) {
    return errorCall('Значение не может быть больше 1 000')
  }

  successCall(toInt)
}, 0)

export default {
  name: 'settingsBonuses',
  components: {
    xTable,
    adminNav,
    xButton,
    popUp,
    numberFormat
  },
  data () {
    return {
      formatSum: {
        decimal: '',
        thousand: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      formError: '',
      statusError: '',
      showReferal: true,
      tabType: 'bonus',
      isEdit: false,
      popUp: false,
      isEditStatus: false,
      newStatus: {
        bonusesName: '',
        bonusRate: '',
        bonusSum: '',
        bonusRefferalNumber: ''
      },
      hi: [
        'Сразу',
        'Не сразу'
      ],
      deleteAsk: [
        'Да',
        'Нет'
      ],
      hiBonusesPeriod: [
        'Сразу',
        'Не сразу'
      ],
      bonusesMob: [
        'Да',
        'Нет'
      ],
      bonusesDelete: [
        'Да',
        'Нет'
      ],
      statusesData: {
        theads: 1,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [
          {
            id: 0,
            bonusesName: 'Добро пожаловать',
            bonusRate: '3%',
            bonusSum: '─',
            bonusRefferalNumber: '─',
            cantDelete: true
          }
        ],
        wordChecker: true,
        optionsX: [
          { name: 'Название статуса', value: 'bonusesName', id: 0 },
          { name: 'Размер бонуса с покупки', value: 'bonusRate', id: 1 },
          { name: 'Когда сумма покупок от', value: 'bonusSum', id: 2 },
          { name: 'Когда рекомендаций от', value: 'bonusRefferalNumber', id: 3 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Название статуса', 'Размер бонуса с покупки', 'Когда сумма покупок от', 'Когда рекомендаций от'],
        namesForHeaderX: []
      },
      formData: {
        name: '',
        greetBonus: '',
        greetBonusDays: 'Сразу',
        greetBonusDaysCanSpend: '',
        greetBonusBurn: 'Нет',
        greetBonusBurnDays: '',
        birthdayBonus: '',
        birthdayBonusDays: '',
        birthdayBonusBurn: 'Нет',
        birthdayBonusBurnDays: '',
        noShoppingBonus: '',
        noShoppingBonusDays: '',
        noShoppingBonusBurn: 'Да',
        noShoppingBonusBurnDays: '',
        bonusAfterBuy: 'Не сразу',
        bonusAfterBuyHours: '',
        percentCanUse: '',
        usePhone: 'Да',
        bonusBurn: 'Нет',
        bonusBurnDays: '',
        clientLostDays: '',
        suspicionPurchaseDay: '',
        suspicionPurchaseWeek: '',
        suspicionPurchaseMonth: '',
        suspicionRecommendDay: '',
        suspicionRecommendWeek: '',
        suspicionRecommendMonth: '',
        recommendBonus: '',
        recommendBonusAward: '0',
        referralLevel1: '',
        referralLevel2: '',
        referralLevel3: '',
        referralAward: '1'
      },
      errors: {
        greetBonusDaysCanSpend: '',
        referralLevel1: '',
        referralLevel2: '',
        referralLevel3: '',
        percentCanUse: '',
        bonusesName: '',
        bonusRate: '',
        bonusSum: '',
        bonusRefferalNumber: '',
        suspicionRecommendDay: '',
        suspicionRecommendWeek: '',
        suspicionRecommendMonth: '',
        suspicionPurchaseDay: '',
        suspicionPurchaseMonth: '',
        name: '',
        greetBonus: '',
        greetBonusBurnDays: '',
        birthdayBonus: '',
        birthdayBonusBurnDays: '',
        recommendBonus: '',
        noShoppingBonus: '',
        noShoppingBonusDays: '',
        noShoppingBonusBurnDays: '',
        bonusAfterBuyHours: '',
        birthdayBonusDays: '',
        suspicionPurchaseWeek: '',
        clientLostDays: '',
        bonusBurnDays: ''
      }
    }
  },
  computed: {
    isCanSaveNewStatus () {
      return !this.errors.bonusesName.length && !this.errors.bonusRate.toString().length && !this.errors.bonusSum.toString().length && !this.errors.bonusRefferalNumber.toString().length
    },
    isStatusError () {
      return !this.statusesData.data.length
    },
    isHaveErrors () {
      const errors = []
      for (const key in this.errors) {
        if (!['greetBonusBurnDays', 'birthdayBonusBurnDays', 'noShoppingBonusBurnDays', 'bonusBurnDays', 'bonusAfterBuyHours'].includes(key)) {
          errors.push(this.errors[key])
        }
      }
      return !!errors.join('').length || !!this.isStatusError
    },
    isHaveEmptyFieldsOnFirstTab () {
      const excludeFields = [
        'recommendBonus',
        'recommendBonusAward',
        'referralLevel1',
        'referralLevel2',
        'referralLevel3',
        'referralAward'
      ]
      for (const key in this.formData) {
        if (!excludeFields.includes(key) && !this.formData[key].toString().length) {
          let checkField = false
          switch (key) {
            case 'greetBonusBurnDays':
              checkField = 'greetBonusBurn'
              break
            case 'birthdayBonusBurnDays':
              checkField = 'birthdayBonusBurn'
              break
            case 'noShoppingBonusBurnDays':
              checkField = 'noShoppingBonusBurn'
              break
            case 'bonusBurnDays':
              checkField = 'bonusBurn'
              break
          }
          if (
            (checkField && this.formData[checkField] !== 'Да') ||
            (key === 'bonusAfterBuyHours' && this.formData.bonusAfterBuy !== 'Не сразу') ||
            (key === 'greetBonusDaysCanSpend' && this.formData.greetBonusDays !== 'Не сразу')
          ) {
            continue
          }
          return true
        }
      }
      return false
    },
    isHaveEmptyFields () {
      for (const key in this.formData) {
        if (!this.formData[key].toString().length) {
          let checkField = false
          switch (key) {
            case 'greetBonusBurnDays':
              checkField = 'greetBonusBurn'
              break
            case 'birthdayBonusBurnDays':
              checkField = 'birthdayBonusBurn'
              break
            case 'noShoppingBonusBurnDays':
              checkField = 'noShoppingBonusBurn'
              break
            case 'bonusBurnDays':
              checkField = 'bonusBurn'
              break
          }
          if (
            (checkField && this.formData[checkField] !== 'Да') ||
            (key === 'bonusAfterBuyHours' && this.formData.bonusAfterBuy !== 'Не сразу') ||
            (key === 'greetBonusDaysCanSpend' && this.formData.greetBonusDays !== 'Не сразу')
          ) {
            continue
          }
          return true
        }
      }
      return false
    },
    bonusesForRequest () {
      return this.statusesData.data.map(el => {
        const sum = parseInt(el.bonusSum)
        const recommendCount = parseInt(el.bonusRefferalNumber)
        const bonus = parseFloat(el.bonusRate)

        const status = {
          name: el.bonusesName,
          sum: isNaN(sum) ? '' : sum,
          recommendCount: isNaN(recommendCount) ? '' : recommendCount,
          bonus: isNaN(bonus) ? '' : bonus
        }
        if (this.isEdit) {
          status.id = el.id
        }
        return status
      })
    },
    biggestStatusId () {
      let biggestId = this.statusesData.data[0].id
      this.statusesData.data.map(el => {
        if (el.id > biggestId) {
          biggestId = el.id
        }

        return biggestId
      })
      return biggestId
    },
    smallestStatusId () {
      let smallesId = this.statusesData.data[0].id
      this.statusesData.data.map(el => {
        if (el.id < smallesId) {
          smallesId = el.id
        }

        return smallesId
      })
      return smallesId
    },
    lessStatus () {
      return !this.isEditStatus
        ? this.statusesData.data[this.statusesData.data.length - 1]
        : (this.newStatus.id !== this.smallestStatusId
            ? this.statusesData.data[this.getPrevStatusI(this.newStatus.id)]
            : false)
    }
  },
  methods: {
    nextTabAction () {
      if (this.isHaveEmptyFieldsOnFirstTab) {
        this.checkEmptyFieldsOnFirstTab()
        this.scrollToErrors()
      } else {
        this.tabType = 'ref'
      }
    },
    scrollToErrors () {
      let needFirstTab = false
      for (const key in this.errors) {
        if (
          !['recommendBonus', 'referralLevel1', 'referralLevel2', 'referralLevel3'].includes(key) &&
          !['greetBonusBurnDays', 'birthdayBonusBurnDays', 'noShoppingBonusBurnDays', 'bonusBurnDays', 'bonusAfterBuyHours'].includes(key) &&
          this.errors[key].length
        ) {
          needFirstTab = true
          break
        }
      }

      if (needFirstTab) {
        this.tabType = 'bonus'
      }

      setTimeout(() => {
        const firstErrElem = window.document.querySelector('.error-border')
        if (firstErrElem) {
          const offset = firstErrElem.offsetTop - 100
          window.scroll({
            top: offset < 0 ? 0 : offset,
            left: 0,
            behavior: 'smooth'
          })
        }
      }, 100)
    },
    getNextStatusI (curId) {
      const sortedIdsToI = {}
      this.statusesData.data.map((val, i) => {
        sortedIdsToI[val.id] = i
        return sortedIdsToI
      })
      let flag = false
      for (const id in sortedIdsToI) {
        if (flag) {
          return sortedIdsToI[id]
        }
        id === curId && (flag = true)
      }
      return false
    },
    getPrevStatusI (curId) {
      const sortedIdsToI = {}
      this.statusesData.data.map((val, i) => {
        sortedIdsToI[1000 - val.id] = i
        return sortedIdsToI
      })
      let flag = false
      for (const id in sortedIdsToI) {
        if (flag) {
          return sortedIdsToI[id]
        }
        (id + curId) === 1000 && (flag = true)
      }
    },
    checkEmptyFieldsOnFirstTab () {
      const checkThisFields = [
        'name',
        'greetBonus',
        'greetBonusBurnDays',
        'birthdayBonus',
        'birthdayBonusBurnDays',
        'noShoppingBonus',
        'noShoppingBonusDays',
        'noShoppingBonusBurnDays',
        'bonusAfterBuyHours',
        'birthdayBonusDays',
        'clientLostDays',
        'bonusBurnDays',
        'percentCanUse',
        'suspicionRecommendDay',
        'suspicionRecommendWeek',
        'suspicionRecommendMonth',
        'suspicionPurchaseDay',
        'suspicionPurchaseWeek',
        'suspicionPurchaseMonth',
        'bonusAfterBuyHours',
        'greetBonusDaysCanSpend'
      ]
      for (const formKey of checkThisFields) {
        if (
          (formKey === 'bonusAfterBuyHours' && this.formData.bonusAfterBuy === 'Не сразу') ||
          (formKey === 'greetBonusDaysCanSpend' && this.formData.greetBonusDays === 'Не сразу')
        ) {
          this.errors[formKey] = !this.formData[formKey].toString().trim().length
            ? 'Заполните поле'
            : ''
        } else if (formKey !== 'formKey' && formKey !== 'greetBonusDaysCanSpend') {
          this.errors[formKey] = !this.formData[formKey].toString().trim().length
            ? 'Заполните поле'
            : ''
        }
      }
    },
    checkEmptyFields () {
      const checkThisFields = [
        'name',
        'greetBonus',
        'greetBonusBurnDays',
        'birthdayBonus',
        'birthdayBonusBurnDays',
        'recommendBonus',
        'noShoppingBonus',
        'noShoppingBonusDays',
        'noShoppingBonusBurnDays',
        'bonusAfterBuyHours',
        'birthdayBonusDays',
        'clientLostDays',
        'bonusBurnDays',
        'percentCanUse',
        'suspicionRecommendDay',
        'suspicionRecommendWeek',
        'suspicionRecommendMonth',
        'suspicionPurchaseDay',
        'suspicionPurchaseWeek',
        'suspicionPurchaseMonth',
        'referralLevel1',
        'referralLevel2',
        'referralLevel3',
        'bonusAfterBuyHours',
        'greetBonusDaysCanSpend'
      ]
      for (const formKey of checkThisFields) {
        if (
          (formKey === 'bonusAfterBuyHours' && this.formData.bonusAfterBuy === 'Не сразу') ||
          (formKey === 'greetBonusDaysCanSpend' && this.formData.greetBonusDays === 'Не сразу')
        ) {
          this.errors[formKey] = !this.formData[formKey].toString().trim().length
            ? 'Заполните поле'
            : ''
        } else if (formKey !== 'formKey' && formKey !== 'greetBonusDaysCanSpend') {
          this.errors[formKey] = !this.formData[formKey].toString().trim().length
            ? 'Заполните поле'
            : ''
        }
      }
    },
    beforeOpenNewStatus () {
      this.popUp = true
      this.isEditStatus = false
    },
    closeStatusPopup () {
      this.popUp = false
      this.isEditStatus = false
      this.statusError = ''
      this.newStatus.bonusesName = ''
      this.newStatus.bonusRefferalNumber = ''
      this.newStatus.bonusSum = ''
      this.newStatus.bonusRate = ''
      delete this.newStatus.id
    },
    validatePercent (variable, val, min = false) {
      if (val.trim() === '') {
        return
      }
      const replacedVal = val.replace(/%/, '')
      if (variable === 'bonusRate') {
        this.newStatus.bonusRate = replacedVal
      } else {
        this.formData[variable] = replacedVal
      }

      this.errors[variable] = ''
      validatePercent(
        replacedVal,
        min,
        newVal => {
          if (variable === 'bonusRate') {
            this.newStatus.bonusRate = newVal
          } else {
            this.formData[variable] = newVal
          }
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    validateSum (variable, val, min = false) {
      if (val.trim() === '') {
        return
      }
      const replacedVal = val.replace(/р\./, '')
      if (variable === 'bonusSum') {
        this.newStatus.bonusSum = replacedVal
      } else {
        this.formData[variable] = replacedVal
      }

      this.errors[variable] = ''
      if (variable === 'bonusSum') {
        this.errors.bonusRefferalNumber = ''
      }
      validateSum(
        replacedVal,
        min,
        newVal => {
          if (variable === 'bonusSum') {
            this.newStatus.bonusSum = newVal
          } else {
            this.formData[variable] = newVal
          }
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    validateCount (variable, val) {
      if (val.trim() === '') {
        return
      }
      this.errors[variable] = ''
      if (variable === 'bonusRefferalNumber') {
        this.errors.bonusSum = ''
      }
      validateCount(
        val,
        newVal => {
          if (variable === 'bonusRefferalNumber') {
            this.newStatus.bonusRefferalNumber = newVal
          } else {
            this.formData[variable] = newVal
          }
        },
        errString => {
          this.errors[variable] = errString
        }
      )
    },
    saveStatus () {
      const newState = {
        ...this.newStatus
      }

      if (!this.isCanSaveNewStatus) {
        return
      }

      const lessStatus = !this.isEditStatus ? this.statusesData.data[this.statusesData.data.length - 1] : (newState.id !== this.smallestStatusId ? this.statusesData.data[this.getPrevStatusI(newState.id)] : false)

      if (newState.id !== this.smallestStatusId) {
        if (!this.newStatus.bonusRate.toString().length) {
          this.errors.bonusRate = 'Заполните поле'
        }
        if (!this.newStatus.bonusSum.toString().length && !this.newStatus.bonusRefferalNumber.toString().length) {
          this.errors.bonusSum = 'Заполните поле'
          this.errors.bonusRefferalNumber = 'Заполните поле'
        }
      }
      if (this.errors.bonusRate || this.errors.bonusSum || this.errors.bonusRefferalNumber) {
        return
      }

      if (lessStatus) {
        const lessRateError = (!parseFloat(lessStatus.bonusRate) || (!!newState.bonusRate && parseFloat(lessStatus.bonusRate) < parseFloat(newState.bonusRate)))
        const lessSumError = (!parseInt(lessStatus.bonusSum) || !parseInt(newState.bonusSum) || (!!newState.bonusSum && parseInt(lessStatus.bonusSum) < parseInt(newState.bonusSum)))
        const lessReffError = (!parseInt(lessStatus.bonusRefferalNumber) || !parseInt(newState.bonusRefferalNumber) || (!!newState.bonusRefferalNumber && parseInt(lessStatus.bonusRefferalNumber) < parseInt(newState.bonusRefferalNumber)))
        const moreThanLess = lessRateError && lessSumError && lessReffError

        if (!moreThanLess) {
          this.statusError = 'Условия присвоения старшего статуса должны быть выше, чем в предыдущем статусе'
          !lessRateError && (this.errors.bonusRate = 'Заполните поле корректно')
          !lessSumError && (this.errors.bonusSum = 'Заполните поле корректно')
          !lessReffError && (this.errors.bonusRefferalNumber = 'Заполните поле корректно')
        } else if (this.statusError === 'Условия присвоения старшего статуса должны быть выше, чем в предыдущем статусе') {
          this.statusError = ''
        }
      }

      if (!this.statusError) {
        const moreStatus = !this.isEditStatus ? false : (newState.id !== this.biggestStatusId ? this.statusesData.data[this.getNextStatusI(newState.id)] : false)
        if (moreStatus) {
          const moreRateError = (!parseFloat(moreStatus.bonusRate) || (!!newState.bonusRate && parseFloat(moreStatus.bonusRate) > parseFloat(newState.bonusRate)))
          const moreSumError = (!parseInt(moreStatus.bonusSum) || !parseInt(newState.bonusSum) || (!!newState.bonusSum && parseInt(moreStatus.bonusSum) > parseInt(newState.bonusSum)))
          const moreReffError = (!parseInt(moreStatus.bonusRefferalNumber) || !parseInt(newState.bonusRefferalNumber) || (!!newState.bonusRefferalNumber && parseInt(moreStatus.bonusRefferalNumber) > parseInt(newState.bonusRefferalNumber)))
          const lessThanMore = moreRateError && moreSumError && moreReffError

          if (!lessThanMore) {
            this.statusError = 'Условия присвоения младшего статуса должны быть ниже, чем в следующем статусе'
            !moreRateError && (this.errors.bonusRate = 'Заполните поле корректно')
            !moreSumError && (this.errors.bonusSum = 'Заполните поле корректно')
            !moreReffError && (this.errors.bonusRefferalNumber = 'Заполните поле корректно')
          } else if (this.statusError === 'Условия присвоения младшего статуса должны быть ниже, чем в следующем статусе') {
            this.statusError = ''
          }
        }
      }

      if (!this.isCanSaveNewStatus || this.statusError) {
        return
      }

      this.popUp = false

      const bonusRate = parseFloat(newState.bonusRate)
      const bonusRateSplit = bonusRate.toString().split('.')
      if (bonusRateSplit.length === 2 && bonusRateSplit[1].length === 1) {
        bonusRateSplit[1] += '0'
      }
      const bonusSum = parseInt(newState.bonusSum)
      const bonusRefferalNumber = parseInt(newState.bonusRefferalNumber)

      if (this.isEditStatus) {
        this.isEditStatus = false
        const findedI = this.statusesData.data.findIndex(el => el.id === this.newStatus.id)

        this.statusesData.data[findedI].bonusesName = newState.bonusesName
        this.statusesData.data[findedI].bonusRate = isNaN(bonusRate) ? '─' : (bonusRateSplit.join('.') + '%')
        this.statusesData.data[findedI].bonusSum = isNaN(bonusSum) ? '─' : (bonusSum + 'р.')
        this.statusesData.data[findedI].bonusRefferalNumber = isNaN(bonusRefferalNumber) ? '─' : bonusRefferalNumber
      } else {
        this.statusesData.data.push({
          id: this.biggestStatusId + 1,
          bonusesName: newState.bonusesName,
          bonusRate: isNaN(bonusRate) ? '─' : (bonusRateSplit.join('.') + '%'),
          bonusSum: isNaN(bonusSum) ? '─' : (bonusSum + 'р.'),
          bonusRefferalNumber: isNaN(bonusRefferalNumber) ? '─' : bonusRefferalNumber
        })
      }

      delete this.newStatus.id
      this.newStatus.bonusesName = ''
      this.newStatus.bonusRate = ''
      this.newStatus.bonusSum = ''
      this.newStatus.bonusRefferalNumber = ''
    },
    editStatus (key) {
      this.newStatus = {
        ...this.statusesData.data.find(el => el.id === key)
      }
      this.popUp = true
      this.isEditStatus = true
    },
    deleteStatus (key) {
      const findedI = this.statusesData.data.findIndex(el => el.id === key)
      this.statusesData.data.splice(findedI, 1)
    },
    onSendForm () {
      this.checkEmptyFields()

      if (this.isHaveErrors || this.isHaveEmptyFields) {
        this.scrollToErrors()
        return
      }

      const formData = {
        ...this.formData
      }
      const formatFormData = {
        name: formData.name,
        greetBonus: parseInt(formData.greetBonus),
        greetBonusDays: formData.greetBonusDays === 'Сразу' ? 1 : 0,
        greetBonusDaysCanSpend: parseInt(formData.greetBonusDaysCanSpend),
        greetBonusBurn: formData.greetBonusBurn === 'Да' ? 1 : 0,
        greetBonusBurnDays: parseInt(formData.greetBonusBurnDays),
        birthdayBonus: parseInt(formData.birthdayBonus),
        birthdayBonusDays: parseInt(formData.birthdayBonusDays),
        birthdayBonusBurn: formData.birthdayBonusBurn === 'Да' ? 1 : 0,
        birthdayBonusBurnDays: parseInt(formData.birthdayBonusBurnDays),
        noShoppingBonus: parseInt(formData.noShoppingBonus),
        noShoppingBonusDays: parseInt(formData.noShoppingBonusDays),
        noShoppingBonusBurn: formData.noShoppingBonusBurn === 'Да' ? 1 : 0,
        noShoppingBonusBurnDays: parseInt(formData.noShoppingBonusBurnDays),
        bonusAfterBuy: formData.bonusAfterBuy === 'Сразу' ? 1 : 0,
        bonusAfterBuyHours: parseInt(formData.bonusAfterBuyHours),
        percentCanUse: parseFloat(formData.percentCanUse),
        usePhone: formData.usePhone === 'Да' ? 1 : 0,
        bonusBurn: formData.bonusBurn === 'Да' ? 1 : 0,
        bonusBurnDays: parseInt(formData.bonusBurnDays),
        clientLostDays: parseInt(formData.clientLostDays),
        suspicionPurchaseDay: parseInt(formData.suspicionPurchaseDay),
        suspicionPurchaseWeek: parseInt(formData.suspicionPurchaseWeek),
        suspicionPurchaseMonth: parseInt(formData.suspicionPurchaseMonth),
        suspicionRecommendDay: parseInt(formData.suspicionRecommendDay),
        suspicionRecommendWeek: parseInt(formData.suspicionRecommendWeek),
        suspicionRecommendMonth: parseInt(formData.suspicionRecommendMonth),
        recommendBonus: parseInt(formData.recommendBonus),
        recommendBonusAward: formData.recommendBonusAward,
        referralLevel1: parseFloat(formData.referralLevel1),
        referralLevel2: parseFloat(formData.referralLevel2),
        referralLevel3: parseFloat(formData.referralLevel3),
        referralAward: formData.referralAward,
        statuses: this.bonusesForRequest
      }

      const editErrPart = this.isEdit ? ('/' + this.$router.currentRoute.value.params.id) : ''
      fetch(window.privateHost + 'activities' + editErrPart, {
        method: this.isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(formatFormData)
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }
        this.$router.push('/private/activity/settings')
        this.$store.commit('animateStore/animateSave')
      }).catch(err => {
        this.formError = err
        this.$store.commit('techStore/checkError', { err })
      })
    },
    onGoBack () {
      this.$router.push('/private/activity/settings')
    }
  },
  created () {
    const id = typeof this.$router.currentRoute.value.params.id !== 'undefined' ? this.$router.currentRoute.value.params.id : false
    if (id) {
      this.isEdit = true
      fetch(window.privateHost + 'activities/' + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        const activityData = data.data

        const percentCanUseSplit = activityData.percentCanUse ? activityData.percentCanUse.toString().split('.') : ['']
        if (percentCanUseSplit.length === 2 && percentCanUseSplit[1].length === 1) {
          percentCanUseSplit[1] += '0'
        }
        const collectPercentCanUseSplit = percentCanUseSplit.join('.')

        const referralLevel1Split = activityData.referralLevel1 ? activityData.referralLevel1.toString().split('.') : ['']
        if (referralLevel1Split.length === 2 && referralLevel1Split[1].length === 1) {
          referralLevel1Split[1] += '0'
        }
        const collectReferralLevel1Split = referralLevel1Split.join('.')

        const referralLevel2Split = activityData.referralLevel2 ? activityData.referralLevel2.toString().split('.') : ['']
        if (referralLevel2Split.length === 2 && referralLevel2Split[1].length === 1) {
          referralLevel2Split[1] += '0'
        }
        const collectReferralLevel2Split = referralLevel2Split.join('.')

        const referralLevel3Split = activityData.referralLevel3 ? activityData.referralLevel3.toString().split('.') : ['']
        if (referralLevel3Split.length === 2 && referralLevel3Split[1].length === 1) {
          referralLevel3Split[1] += '0'
        }
        const collectReferralLevel3Split = referralLevel3Split.join('.')

        this.formData = Object.assign(this.formData, {
          name: activityData.name,
          greetBonus: activityData.greetBonus,
          greetBonusBurnDays: activityData.greetBonusBurnDays || '',
          birthdayBonus: activityData.birthdayBonus,
          birthdayBonusDays: activityData.birthdayBonusDays,
          birthdayBonusBurnDays: activityData.birthdayBonusBurnDays || '',
          noShoppingBonus: activityData.noShoppingBonus,
          noShoppingBonusDays: activityData.noShoppingBonusDays,
          noShoppingBonusBurnDays: activityData.noShoppingBonusBurnDays || '',
          bonusAfterBuyHours: activityData.bonusAfterBuyHours || '',
          percentCanUse: collectPercentCanUseSplit ? `${collectPercentCanUseSplit}%` : '',
          bonusBurnDays: activityData.bonusBurnDays || '',
          clientLostDays: activityData.clientLostDays,
          suspicionPurchaseDay: activityData.suspicionPurchaseDay,
          suspicionPurchaseWeek: activityData.suspicionPurchaseWeek,
          suspicionPurchaseMonth: activityData.suspicionPurchaseMonth,
          suspicionRecommendDay: activityData.suspicionRecommendDay,
          suspicionRecommendWeek: activityData.suspicionRecommendWeek,
          suspicionRecommendMonth: activityData.suspicionRecommendMonth,
          recommendBonus: activityData.recommendBonus,
          referralLevel1: collectReferralLevel1Split ? `${collectReferralLevel1Split}%` : '',
          referralLevel2: collectReferralLevel2Split ? `${collectReferralLevel2Split}%` : '',
          referralLevel3: collectReferralLevel3Split ? `${collectReferralLevel3Split}%` : '',
          recommendBonusAward: activityData.recommendBonusAward,
          referralAward: activityData.referralAward,
          greetBonusBurn: activityData.greetBonusBurn === 1 ? 'Да' : 'Нет',
          birthdayBonusBurn: activityData.birthdayBonusBurn === 1 ? 'Да' : 'Нет',
          noShoppingBonusBurn: activityData.noShoppingBonusBurn === 1 ? 'Да' : 'Нет',
          usePhone: activityData.usePhone === 1 ? 'Да' : 'Нет',
          bonusBurn: activityData.bonusBurn === 1 ? 'Да' : 'Нет',
          greetBonusDays: activityData.greetBonusDays === 1 ? 'Сразу' : 'Не сразу',
          greetBonusDaysCanSpend: activityData.greetBonusDaysCanSpend || '',
          bonusAfterBuy: activityData.bonusAfterBuy === 1 ? 'Сразу' : 'Не сразу'
        })

        let smallestId = activityData.statuses[0].id
        this.statusesData.data = activityData.statuses.map(el => {
          if (el.id < smallestId) {
            smallestId = el.id
          }

          const bonusSplit = el.bonus ? el.bonus.toString().split('.') : ['']
          if (bonusSplit.length === 2 && bonusSplit[1].length === 1) {
            bonusSplit[1] += '0'
          }
          const collectBonusSplit = bonusSplit.join('.')

          return {
            id: el.id,
            bonusesName: el.name,
            bonusRate: collectBonusSplit ? `${collectBonusSplit}%` : '─',
            bonusSum: el.sum ? `${el.sum}р.` : '─',
            bonusRefferalNumber: el.recommendCount || '─'
          }
        })

        const smallestI = this.statusesData.data.findIndex(el => el.id === smallestId)
        this.statusesData.data[smallestI].cantDelete = true
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    }
  }
}
</script>

<style lang="scss" src="../vPrivate/vPrivate.scss" scoped />
<style lang="scss" src="../settingsBonuses/settingsBonuses.scss" scoped />
<style lang="scss" src="./vPrivateActivityBonuses.scss" scoped />
<style lang="scss" src="../settingsReferrals/settingsReferrals.scss" scoped />
