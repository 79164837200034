import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
// import store from '@/store/store'

const router = createRouter({
  history: createWebHistory(),
  routes
})

// TODO по углам с версткой хз что, надо узнать что за браузер хром 1920х1080
// TODO autocomplete

// TODO файлы в сообщения!!!
// TODO капча в welcome
// TODO вывод кода телефона по ip в welcome

router.beforeEach(function (target, current, next) {
  // if (!store.state.techStore.isAuth && target.name !== 'login') {
  //   next({
  //     name: 'login'
  //   })
  // } else {
  //   next()
  // }
  // localStorage.setItem('access_token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZDNjNDQ1ODI4NmMwNzJkZTYzZGU1M2RkNDA4NjliNmVjYjAxODZmZDNmMzE0YTA5NTllNTI1Y2RiODc4MjkyNzQwYTIyYWU3Y2FlNmI5OTUiLCJpYXQiOjE2NTk4MjM5OTMuMDgzNjIyLCJuYmYiOjE2NTk4MjM5OTMuMDgzNjI1LCJleHAiOjE2OTEzNTk5OTMuMDc4NTE2LCJzdWIiOiI0MiIsInNjb3BlcyI6W119.tEfzXfuFiiDzI1nxi-R3xUvLtUBF5Ta2Rk_UYifM1d9rBOpup6sYcoKXwBehmRCB0OxXBlNBwnO7M787e8onk6cuUuAfdj_Wl1X5UsElreSWZqtJLS4vr0qe7PluD_YVTCafNVwvLYqWoF-4U5sVvlXWHZsRGa6-Wb_E4DtVts5ospqEJicWDICAxQFqM5bb7Bv6VlTyCnI59gCY_v0-7ITjupNUC6W9Tu5en0XDX83-i337F4wdMfJEbmKxVxMdXjjiUYv_G8Pt_QZPYf3KeMFNxsH0z3cj0CTPKSVM5dSMWTcoYHHLztBvxAjecg3FX0tVYfmJRPXkQHJ7TGhhXx9QkLJJPacfoFIY6G73yeRzvVCo2zH2YZu00hJL4ORPP7jalxXNyg1rWTbHdVDVxUqhNt84WqENF-74QnxO0kVbsSQS6hC42htvOEfa1aI6Ge_KVYv0d88fCY6LGMVK8HAEdFBMuvpg8OTnLhuvTKPNKAL5_yAGnRkJQeCOy7K9hXh0ajQF6LeJn5baEEFSRLFGm5gn8--1VimDvxt5k8wFyT8A_WTbUyV77Gu6VEaWfCQZx7DWSc4sYOa7py3KyNy90hVxqtSuSXa0GPRbPHd-ZrZRDKcv9yInd-d3gpHgmZK5Z8NJyiRAR3iNJBnNnjKS_-Q48-RjD0DS9JtWaS0')
  next()
  // TODO если мы идем не в /login - проверить токен,
  //  если токен валидный, то проход разрешен, иначе редирект в login, (продление токена!!!)
})

export default router
