<template>
  <publicArea>
      <router-view />
  </publicArea>
  <helper />
  <errorPopup/>
</template>

<script>
import publicArea from '@/components/publicArea'
import helper from '@/components/helper'
import errorPopup from '@/components/errorPopup'

export default {
  name: 'account',
  components: {
    publicArea,
    helper,
    errorPopup
  }
}
</script>

<style lang="scss" src="./account.scss" scoped />
