<template>
  <div class="filter-item">
    <button @click="toggleInput" class="filter-item-button">
      <div class="filter-item-text">
        {{item.name}}
      </div>
      <div class="filter-item-arrow" :class="show ? 'active' : 'disactive'">
        <icon name="arrow" />
      </div>
    </button>

    <div class="filter-item-finder" :class="item.type === 'checkbox' ? 'finder-checkbox' : ''" v-show="show">

      <div v-show="item.type==='date'">

          <input  :value="item.valueFrom" type="date" class="input-date d-i" @change='$emit("logChange", $event.target.value, item.name, "from")'>
        -
          <input  :value="item.valueTo" type="date" class="input-date d-i" @change='$emit("logChange", $event.target.value, item.name, "to")'>

      </div>

      <div v-show="item.type==='input'">

          <input :value="item.value" type="text" @input='$emit("logChange", $event.target.value, item.name)'>

      </div>
      <div v-show="item.type === 'number'" class="p-r pl-t-10">
        <span class="number-sp">от</span>
        <input :value="item.valueFrom" class="input-number" type="number" @change='$emit("logChange", $event.target.value, item.name, "from")'>
        <span class="number-sp ml-15">до</span>
        <input :value="item.valueTo" class="input-number ml-15" type="number" @change='$emit("logChange", $event.target.value, item.name, "to")'>
      </div>
      <div v-show="item.type === 'checkbox'" v-for="(itemCheck, index) in inputNumber" :key="itemCheck" class="checkbox-filter">
        <checkboxInput
          :name="texting(item, index + 1)"
          :checked="checking(item, index + 1)"
          :fontRegular="true"
          @change='$emit("logChange", $event.target.checked, item.name, "check" + (index + 1))'
        />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxInput from '@/components/checkboxInput/checkboxInput'
import Icon from '@/components/icons/icon'

export default {
  name: 'xFilterItem',
  components: {
    CheckboxInput,
    Icon
  },
  data () {
    return {
      show: false
    }
  },
  props: {
    item: {
      type: Object
    },
    inputNumber: {
      type: Number
    }
  },
  methods: {
    toggleInput () {
      this.show = !this.show
    },
    checking (item, index) {
      const keys = Object.keys(item)
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === 'check' + index) {
          const value = keys[i]
          return item[value]
        }
      }
    },
    texting (item, index) {
      const filterTextKey = 'filterText' + index
      const textKey = 'text' + index
      return item[filterTextKey] || item[textKey]
    }
  }
}
</script>

<style lang="scss" src="./xFilterItem.scss" scoped>

</style>
