<template>
  <div class="public">
    <div class="public-bg public-bg-left">
      <icon class="public-bg-figure" name="orangeFigure" />
      <icon v-if="humans" class="public-figure public-figure-left" name="publicMan" />
    </div>
    <div class="public-bg public-bg-right">
      <icon class="public-bg-figure" name="orangeFigure" />
      <icon v-if="humans" class="public-figure public-figure-right" name="publicWoman" />
    </div>

    <slot />

  </div>
</template>

<script>
export default {
  name: 'publicArea',
  props: {
    humans: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" src="./publicArea.scss" scoped />
