<template>
  <svg preserveAspectRatio="none" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_148_6741)">
      <path d="M19.0133 0.986462C18.4121 0.385301 17.5829 0.0370428 16.6791 0.00387528C15.8209 -0.0292922 14.9419 0.211172 14.1459 0.700393C13.7935 0.915981 13.6816 1.37618 13.8972 1.72444C14.1128 2.07684 14.573 2.18878 14.9212 1.9732C16.0033 1.30985 17.2512 1.33887 17.956 2.04368C18.3706 2.45827 18.5613 3.05943 18.4909 3.73522C18.4162 4.46905 18.0555 5.19044 17.4751 5.77087C16.8947 6.3513 16.1691 6.712 15.4395 6.78663C14.7637 6.85296 14.1625 6.66225 13.7479 6.2518C13.4784 5.98232 13.1219 5.83721 12.7446 5.83721C12.3632 5.83721 12.0066 5.98646 11.7371 6.2518C11.4677 6.52129 11.3225 6.87784 11.3225 7.25926C11.3225 7.64069 11.4718 7.99724 11.7413 8.26673C12.0108 8.53621 12.3673 8.68132 12.7446 8.68132C13.126 8.68132 13.4826 8.53207 13.7521 8.26673C13.8101 8.20868 13.864 8.14649 13.9096 8.08016C14.3035 8.22112 14.7264 8.29575 15.1741 8.29575C15.3109 8.29575 15.4519 8.28746 15.5929 8.27502C15.6053 8.27502 15.6177 8.27087 15.6302 8.27087L15.7587 10.1324C15.7877 10.5553 15.6302 10.9699 15.3317 11.2725L8.37893 18.217C7.98921 18.6067 7.35074 18.6067 6.96102 18.217L1.78275 13.0387C1.59204 12.848 1.48839 12.5951 1.48839 12.3297C1.48839 12.0603 1.59204 11.8115 1.78275 11.6208L8.73134 4.6722C9.02984 4.37369 9.44444 4.21615 9.86732 4.24517L16.0406 4.65562C16.4511 4.68464 16.8076 4.37369 16.8366 3.9591C16.8657 3.54865 16.5547 3.1921 16.1401 3.16308L9.96682 2.75263C9.11691 2.69459 8.27943 3.00968 7.67827 3.61499L0.729684 10.5636C0.257048 11.0362 0 11.6622 0 12.3297C0 12.9972 0.261194 13.6233 0.729684 14.0959L5.90381 19.27C6.38888 19.7551 7.0315 19.9997 7.66998 19.9997C8.30845 19.9997 8.94693 19.7551 9.43615 19.27L16.3847 12.3215C16.99 11.7161 17.3051 10.8787 17.2471 10.0246L17.0978 7.84384C17.6202 7.59509 18.1053 7.25097 18.5365 6.82394C19.3574 6.00305 19.8715 4.95827 19.9793 3.88447C20.0912 2.75263 19.7471 1.72444 19.0133 0.986462Z" fill="currentColor"/>
      <path d="M4.73462 11.4485C4.73462 11.8684 5.07493 12.2087 5.49476 12.2087H7.77519V14.4891C7.77519 14.9089 8.1155 15.2492 8.53533 15.2492C8.95515 15.2492 9.29547 14.9089 9.29547 14.4891V12.2087H11.5759C11.9957 12.2087 12.336 11.8684 12.336 11.4485C12.336 11.0287 11.9957 10.6884 11.5759 10.6884H9.29547V8.40797C9.29547 7.98814 8.95515 7.64783 8.53533 7.64783C8.1155 7.64783 7.77519 7.98814 7.77519 8.40797V10.6884H5.49476C5.07493 10.6884 4.73462 11.0287 4.73462 11.4485Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_148_6741">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
