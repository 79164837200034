<template>
  <div class="activity-header">
    <adminNav>
      <router-link to="/private/clients">
        Клиенты
      </router-link>
      <router-link to="/private/activity/settings">
        Виды деятельности
      </router-link>
      <router-link to="/private/logs">
        Просмотр логов
      </router-link>
      <router-link to="/private/other/settings">
        Прочие настройки
      </router-link>
    </adminNav>
  </div>
  <div class="notify">
    <adminNotify align="left">
      <div class="companies-notify">
        Бесплатный пробный период
        <div class="timer">
          <textInput :defaultValue="subscribeDays" @onChange="changeSubscribeDays"/>
        </div>
        дней
      </div>
    </adminNotify>
  </div>

  <form class="currency-block" action="javascript:void(0)" ref="currency">
    <div class="layout-1">
      <div class="s-status bonuses-block">
        <div class="bonuses-header">
          <h2 class="title">Справочник валют</h2>
          <div class="s-status-create">
            <button class="link" @click="currencyPopup = true">Создать валюту</button>
          </div>
        </div>
        <div class="s-status-table">
          <xTable v-bind="currencyData">
            <template v-slot="{ records }" >
              <td>
                <div class="table-action pr">
                  <button class="custom-note-wrap wf" @click="editCurrency(records.id)">
                    <icon name="edit" class="table-icon" />
                    <div class="custom-note">
                      Внесенные изменения отразятся во всех компаниях, где выбрана эта валюта
                    </div>
                  </button>
                  <button v-if="records.active === 1" class="custom-note-wrap wf" @click="confirmDeleteCurrencyOrPhone(records.id, 'currency')">
                    <icon name="delete" class="table-icon" />
                    <div class="custom-note">
                      После удаления валюта будет недоступна для новых компаний. В ранее созданных компаниях валюта останется
                    </div>
                  </button>
                </div>
              </td>
            </template>
          </xTable>
        </div>
      </div>
    </div>
  </form>
  <pop-up :open="currencyPopup" @close="closeCurrencyPopup" :privateView="true">
    <h2 class="x-pop-title">{{ isEditCurrency ? 'Редактирование' : 'Создание' }} валюты</h2>
    <div class="x-input-item">
      <div class="input-name">Название валюты</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="currencyFormError.name && 'error-border'">
            <input class="input" v-model="currencyForm.name" @input="currencyFormError.name = ''">
          </div>
          <span class="error" v-if="currencyFormError.name">{{ currencyFormError.name }}</span>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Краткое обозначение валюты</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="currencyFormError.code && 'error-border'">
            <input class="input" v-model="currencyForm.code" @input="currencyFormError.code = ''">
          </div>
          <span class="error" v-if="currencyFormError.code">{{ currencyFormError.code }}</span>
        </div>
      </div>
    </div>
    <xButton additionalClass="mt-20" @onClick="saveCurrency">Сохранить</xButton>
  </pop-up>

  <form class="currency-block" action="javascript:void(0)" ref="phones">
    <div class="layout-1">
      <div class="s-status bonuses-block">
        <div class="bonuses-header">
          <h2 class="title">Справочник телефонов</h2>
          <div class="s-status-create">
            <button class="link" @click="phonePopup = true">Создать новый формат</button>
          </div>
        </div>
        <div class="s-status-table">
          <xTable v-bind="phoneData">
            <template v-slot="{ records }" >
              <td>
                <div class="table-action pr">
                  <button class="custom-note-wrap wf" @click="editPhone(records.id)">
                    <icon name="edit" class="table-icon" />
                    <div class="custom-note">
                      Внесенные изменения отразятся во всех компаниях, где выбран этот формат
                    </div>
                  </button>
                  <button v-if="records.active === 1" class="custom-note-wrap wf" @click="confirmDeleteCurrencyOrPhone(records.id, 'phone')">
                    <icon name="delete" class="table-icon" />
                    <div class="custom-note">
                      После удаления данный формат будет недоступен для новых компаний. В ранее созданных компаниях формат останется
                    </div>
                  </button>
                </div>
              </td>
            </template>
          </xTable>
        </div>
      </div>
    </div>
  </form>
  <pop-up :open="phonePopup" @close="closePhonePopup" :privateView="true">
    <h2 class="x-pop-title">{{ isEditPhone ? 'Редактирование' : 'Создание' }} формата телефонов</h2>
    <div class="x-input-item">
      <div class="input-name">Графический элемент флага страны</div>
      <div class="input-content">
        <div class="input-wrapper" :class="phoneForm.icon ? ' image-exist' : ''">
          <div v-if="phoneForm.icon" class="current-img-container">
            <img :src="phoneForm.icon" class="current-img">
          </div>
          <div class="input-container" :class="phoneFormError.icon && 'error-border'">
            <div class="file-upload-wrap">
              <label for="phone-ico" class="file-upload">
                Выберите файл
              </label>
            </div>
            <input class="input hidden" id="phone-ico" type="file" @input="onFileSelect">
          </div>
          <span class="error" v-if="phoneFormError.icon">{{ phoneFormError.icon }}</span>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Название страны</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="phoneFormError.country && 'error-border'">
            <input class="input" v-model="phoneForm.country" @input="phoneFormError.country = ''">
          </div>
          <span class="error" v-if="phoneFormError.country">{{ phoneFormError.country }}</span>
        </div>
      </div>
    </div>
    <div class="x-input-item">
      <div class="input-name">Кoд страны</div>
      <div class="input-content">
        <div class="input-wrapper">
          <div class="input-container" :class="phoneFormError.code && 'error-border'">
            <input class="input" :value="phoneForm.code" @input="changePhoneCode">
          </div>
          <span class="error" v-if="phoneFormError.code">{{ phoneFormError.code }}</span>
        </div>
      </div>
    </div>
    <xButton additionalClass="mt-20" @click="savePhone">Сохранить</xButton>
  </pop-up>

  <pop-up :open="deletePopup" @close="closeDeletePopup" sizer="pop-verysmall" :privateView="true">
    <h2 class="x-pop-title">{{ currencyPopupDelete ? 'Вы удаляете валюту' : 'Вы удаляете формат телефонов' }}</h2>
    <p v-if="currencyPopupDelete" class="x-pop-desc-black">
      После удаления валюта будет недоступна для новых компаний, не будет отображаться в справочниках. В ранее созданных компаниях эта валюта останется.
    </p>
    <p v-else class="x-pop-desc-black">
      После удаления формат телефонов будет недоступен для новых компаний, не будет отображаться в справочниках. В ранее созданных компаниях этот формат останется.
    </p>
    <div class="x-pop-footer">
      <xButton @click="deleteCurrencyOrPhone">Удалить</xButton>
      <button @click="closeDeletePopup" class="link x-pop-back">Отменить</button>
    </div>
  </pop-up>
</template>

<script>
import debounce from 'lodash.debounce'
import textInput from '@/components/textInput'
import xTable from '@/components/xTable'
import Icon from '@/components/icons/icon'
import popUp from '@/components/popUp'
import adminNav from '@/components/adminNav'
import adminNotify from '@/components/adminNotify'
import xButton from '@/components/xButton'

const updateSubscribeDays = debounce((val) => {
  fetch(window.privateHost + 'settings/period', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    },
    body: JSON.stringify({
      days: val
    })
  }).then(async response => {
    const data = await response.json()

    if (!response.ok || !data.success) {
      const error = (data && data.message) || response.status
      return Promise.reject(error)
    }
  }).catch(err => {
    this.$store.commit('techStore/checkError', { err })
  })
}, 1500)

export default {
  name: 'vPrivateOtherSettings',
  components: {
    Icon,
    xTable,
    adminNav,
    popUp,
    adminNotify,
    textInput,
    xButton
  },
  emits: ['titleName'],
  data () {
    return {
      subscribeDays: '─',
      deletePopup: false,
      deleteId: 0,
      currencyPopup: false,
      isEditCurrency: false,
      currencyPopupDelete: false,
      currencyForm: {
        name: '',
        code: '',
        active: 1,
        id: 0
      },
      currencyFormError: {
        name: '',
        code: ''
      },
      currencyData: {
        theads: 1,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Валюта', value: 'name', id: 0 },
          { name: 'Краткое обозначение', value: 'code', id: 1 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Валюта', 'Краткое обозначение'],
        namesForHeaderX: []
      },
      phonePopup: false,
      isEditPhone: false,
      phonePopupDelete: false,
      phoneForm: {
        icon: '',
        country: '',
        code: '',
        active: 1,
        id: 0
      },
      phoneFormError: {
        icon: '',
        country: '',
        code: ''
      },
      phoneData: {
        theads: 1,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        iconExist: true,
        data: [],
        wordChecker: true,
        optionsX: [
          { name: 'Графический элемент флага страны', value: 'icon', id: 0 },
          { name: 'Название страны', value: 'country', id: 1 },
          { name: 'Кoд страны', value: 'code', id: 3 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Графический элемент флага страны', 'Название страны', 'Кoд страны'],
        namesForHeaderX: []
      }
    }
  },
  methods: {
    changePhoneCode (event) {
      const code = event.target.value

      this.phoneFormError.code = ''
      this.phoneForm.code = code
      if (code.replace('+', '').length > 3) {
        this.phoneFormError.code = 'Код страны должен содержать не более трех цифр'
      }
    },
    saveCurrency () {
      if (!this.currencyForm.name.length) {
        this.currencyFormError.name = 'Заполните поле'
      }
      if (!this.currencyForm.code.length) {
        this.currencyFormError.code = 'Заполните поле'
      }
      if (this.currencyFormError.code.length || this.currencyFormError.name.length) {
        return
      }

      let newCurrencyData = {}

      if (this.isEditCurrency) {
        const changeI = this.currencyData.data.findIndex(el => el.id === this.currencyForm.id)
        this.currencyData.data[changeI].name = this.currencyForm.name
        this.currencyData.data[changeI].code = this.currencyForm.code
        this.currencyData.data[changeI].active = 1

        newCurrencyData = this.currencyData.data[changeI]
      } else {
        const newCur = {
          name: this.currencyForm.name,
          code: this.currencyForm.code,
          active: 1,
          id: this.currencyData.data.length
        }
        this.currencyData.data.push(newCur)

        newCurrencyData = newCur
      }

      fetch(window.host + 'currencies/' + (this.isEditCurrency ? this.currencyForm.id : ''), {
        method: this.isEditCurrency ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          name: newCurrencyData.name,
          code: newCurrencyData.code
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit('animateStore/animateSave')
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })

      this.closeCurrencyPopup()
    },
    onFileSelect () {
      this.phoneFormError.icon = ''
      const fileIco = document.getElementById('phone-ico')
      if (fileIco.files.length) {
        const fileLabel = document.querySelector('[for="phone-ico"]')
        fileLabel.textContent = fileIco.files[0].name

        this.phoneForm.icon = ''
      }
    },
    async savePhone () {
      if (!this.phoneForm.country.length) {
        this.phoneFormError.country = 'Заполните поле'
      }
      if (!this.phoneForm.code.length) {
        this.phoneFormError.code = 'Заполните поле'
      }

      const fileIco = document.getElementById('phone-ico')
      if (!this.phoneForm.icon.length && !fileIco.files.length) {
        this.phoneFormError.icon = 'Выберите изображение'
      } else if (fileIco.files.length && !fileIco.files[0].type.includes('image')) {
        this.phoneFormError.icon = 'Только изображения'
      }

      if (this.phoneFormError.country.length || this.phoneFormError.code.length || this.phoneFormError.icon.length) {
        return
      }

      let newPhoneData = {}

      if (this.isEditPhone) {
        const changeI = this.phoneData.data.findIndex(el => el.id === this.phoneForm.id)
        this.phoneData.data[changeI].country = this.phoneForm.country
        this.phoneData.data[changeI].code = this.phoneForm.code
        this.phoneData.data[changeI].active = 1

        newPhoneData = this.phoneData.data[changeI]
      } else {
        newPhoneData = {
          icon: null,
          country: this.phoneForm.country,
          code: this.phoneForm.code,
          active: 1,
          id: this.phoneData.data.length
        }
      }

      const formData = new FormData()
      formData.append('country', newPhoneData.country)
      formData.append('code', newPhoneData.code)
      if (this.isEditPhone) {
        formData.append('_method', 'put') // laravel bug
      }

      if (document.getElementById('phone-ico').files.length) {
        const file = document.getElementById('phone-ico').files[0]
        const arrayBuffer = await file.arrayBuffer()
        const fileBlob = new Blob([new Uint8Array(arrayBuffer)], {
          type: file.type
        })
        formData.append('icon', fileBlob, file.name)
      }

      fetch(window.host + 'phones/' + (this.isEditPhone ? this.phoneForm.id : ''), {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: formData
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        if (this.isEditPhone) {
          const changeI = this.phoneData.data.findIndex(el => el.id === this.phoneForm.id)
          this.phoneData.data[changeI].icon = window.domen + data.data.icon
        } else {
          const newData = data.data

          this.phoneData.data.push({
            icon: window.domen + newData.icon,
            country: newData.country,
            code: newData.code,
            active: newData.active,
            id: newData.id
          })
        }

        this.$store.commit('animateStore/animateSave')
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })

      this.closePhonePopup()
    },
    deleteCurrencyOrPhone () {
      if (this.currencyPopupDelete) {
        const deleteI = this.currencyData.data.findIndex(el => el.id === this.deleteId)
        this.currencyData.data[deleteI].active = 0

        fetch(window.host + 'currencies/' + this.deleteId, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      } else {
        const deleteI = this.phoneData.data.findIndex(el => el.id === this.deleteId)
        this.phoneData.data[deleteI].active = 0

        fetch(window.host + 'phones/' + this.deleteId, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }

      this.closeDeletePopup()
    },
    confirmDeleteCurrencyOrPhone (id, type) {
      this.deleteId = id
      this.currencyPopupDelete = type === 'currency'
      this.phonePopupDelete = type === 'phone'
      this.deletePopup = true
    },
    editCurrency (id) {
      this.currencyForm = { ...this.currencyData.data.find(el => el.id === id) }
      this.isEditCurrency = true
      this.currencyPopup = true
    },
    editPhone (id) {
      this.phoneForm = { ...this.phoneData.data.find(el => el.id === id) }
      this.isEditPhone = true
      this.phonePopup = true
    },
    closeCurrencyPopup () {
      this.currencyPopup = false
      setTimeout(() => {
        this.isEditCurrency = false
        this.currencyForm = {
          name: '',
          code: '',
          active: 1,
          id: 0
        }
      }, 350)
    },
    closePhonePopup () {
      this.phonePopup = false
      setTimeout(() => {
        this.isEditPhone = false
        this.phoneForm = {
          country: '',
          code: '',
          icon: '',
          active: 1,
          id: 0
        }
        document.getElementById('phone-ico').value = ''
        document.querySelector('[for="phone-ico"]').textContent = 'Выберите файл'
      }, 350)
    },
    closeDeletePopup () {
      this.deletePopup = false
      setTimeout(() => {
        this.currencyPopupDelete = false
        this.phonePopupDelete = false
        this.deleteId = 0
      }, 350)
    },
    changeSubscribeDays (newVal) {
      const correctVal = newVal.trim().replace(/\D+/gm, '')
      this.subscribeDays = correctVal

      if (correctVal.length && correctVal > 0) {
        updateSubscribeDays(correctVal)
      }
    }
  },
  created () {
    this.$emit('titleName', 'Кабинет администратора системы')

    fetch(window.privateHost + 'settings/period', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.subscribeDays = data.data.value
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    fetch(window.host + 'currencies/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.currencyData.data = data.data.map(el => {
        return {
          name: el.name,
          code: el.code,
          active: el.active,
          id: el.id
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })

    fetch(window.host + 'phones/public/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.phoneData.data = data.data.map(el => {
        return {
          icon: window.domen + el.icon,
          country: el.country,
          code: el.code,
          active: el.active,
          id: el.id
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="../vPrivate/vPrivate.scss" scoped/>
<style lang="scss" src="./vPrivateOtherSettings.scss" scoped/>
