<template>
  <router-link class="logo-link" to="/admin/clients">
    <icon class="logo" name="logo"/>
  </router-link>
</template>

<script>
export default {
  name: 'logo'
}
</script>

<style lang="scss" src="./logo.scss" scoped />
