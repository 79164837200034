<template>
  <div class="account-wrapper">
    <template v-if="logo === true">
      <logo />
    </template>
    <h1 class="title">
      {{ title }}
    </h1>
    <p class="desc">
      {{ desc }}
    </p>

    <slot />

  </div>
</template>

<script>
import logo from '@/components/logo'

export default {
  name: 'accountContent',
  components: {
    logo
  },
  props: {
    title: {
      type: String
    },
    desc: {
      type: String,
      default: ''
    },
    logo: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" src="./accountContent.scss" scoped />
