<template>
  <nav class="nav">
    <slot />
  </nav>
</template>

<script>
export default {
  name: 'adminNav'
}
</script>

<style lang="scss" src="./adminNav.scss" scoped />
