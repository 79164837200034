<template>
  <input
    v-model="currentValue"
    type="tel"
    @input="onInput($event)"
    @focus="onFocus($event)"
  />
</template>

<script>
import { format, unformat, setCursor, setCursorPosition } from './utils'
import defaultOptions from './defaultOptions'

export default {
  name: 'VueNumberFormat',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    format: {
      type: Function
    },
    unFormat: {
      type: Function
    },
    options: {
      type: Object,
      required: false
    },
    max: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      currentValue: ''
    }
  },
  emits: ['input', 'update:value'],
  watch: {
    currentValue (newVal) {
      const valForFormat = this.max && unformat(newVal, this.mergedOptions) > this.max ? this.max : newVal
      this.currentValue = format(valForFormat, this.mergedOptions)
    },
    value (newVal) {
      this.currentValue = format(newVal, this.mergedOptions)
    }
  },
  computed: {
    mergedOptions () {
      const options = this.$vueNumberFormatOptions || defaultOptions
      if (this.options) {
        return Object.assign({}, options, this.options)
      }
      return options
    }
  },

  created () {
    if (!this.$vueNumberFormatOptions) this.$vueNumberFormatOptions = defaultOptions
  },

  methods: {

    onFocus ($event) {
      setCursor($event.target, ($event.target.value.length - this.mergedOptions.suffix.length))
    },

    onInput ($event) {
      setCursorPosition($event.target, this.mergedOptions)
      const value = unformat($event.target.value, this.mergedOptions)
      this.updateValue(value)
    },

    updateValue (value) {
      if (this.$vueNumberFormatOptions && this.$vueNumberFormatOptions.vueVersion === 'v2') {
        this.$emit('input', value)
      } else {
        this.$emit('input', value)
        this.$emit('update:value', value)
      }
    }

  }
}
</script>
