<template>
  <svg preserveAspectRatio="none" viewBox="0 0 826 749" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.8" d="M822.782 666.773C803.013 760.91 719.941 761.923 675.295 730.139C611.769 684.993 624.208 646.529 514.037 701.999C476.721 720.826 429.632 726.9 389.873 710.097C361.442 697.95 338.564 674.466 331.012 646.529C326.792 631.143 327.236 614.947 324.57 599.157C321.905 583.366 315.686 566.968 301.914 557.453C288.587 548.14 270.374 546.723 253.715 548.343C213.289 551.987 175.751 570.612 134.881 570.814C100.453 571.017 61.5825 559.275 37.5937 536.196C5.83081 506.032 0.944213 460.887 0.0557402 421.207C-0.832733 374.847 8.94047 328.285 26.4878 284.759C49.1439 229.087 84.9049 178.071 127.552 133.128C165.534 93.2463 209.735 57.4135 261.267 32.7152C342.562 -5.9518 473.168 -25.3865 526.254 61.8673C590.224 166.936 468.503 228.682 585.115 238.602C654.86 244.473 762.143 294.072 702.171 416.551C642.199 539.03 851.213 531.135 822.782 666.773Z" fill="url(#paint0_linear_584:5114)"/>
    <defs>
      <linearGradient id="paint0_linear_584:5114" x1="431.626" y1="389.399" x2="350.169" y2="665.457" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7F27"/>
        <stop offset="1" stop-color="#FFBE91"/>
      </linearGradient>
    </defs>
  </svg>
</template>
