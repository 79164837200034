<template>
  <div class="notify" :class="[align, color]">
      <slot />
  </div>
</template>

<script>
export default {
  name: 'adminNotify',
  props: {
    align: {
      validator (value) {
        return ['left'].includes(value)
      }
    },
    color: {
      validator (value) {
        return ['orange'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" src="./adminNotify.scss" scoped />
