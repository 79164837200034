<template>
  <button :class="[color, {'toggler-active': isOpened}]" @click="toggle" class="toggler__container">
    <div class="toggler-item"></div>
    <div class="toggler-item"></div>
    <div class="toggler-item"></div>
  </button>
</template>

<script>
export default {
  name: 'toggler',
  props: {
    color: {
      validator (value) {
        return ['white'].includes(value)
      }
    }
  },
  data () {
    return {
      isOpened: false
    }
  },
  watch: {
    $route: function (to, from) {
      this.isOpened = false
    }
  },
  methods: {
    toggle () {
      this.isOpened = !this.isOpened
    }
  }
}
</script>

<style lang="scss" src="./toggler.scss" scoped />
