<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="980.000000pt" height="980.000000pt" viewBox="0 0 980.000000 980.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
       fill="#808080" stroke="none">
      <path d="M2740 9789 c-124 -24 -267 -133 -330 -250 -51 -96 -60 -162 -60 -456
l0 -263 -724 0 c-778 0 -810 -2 -961 -53 -287 -98 -514 -325 -612 -612 -56
-166 -53 62 -53 -3745 0 -3807 -3 -3579 53 -3745 98 -287 325 -514 612 -612
166 -56 -105 -53 4235 -53 4340 0 4069 -3 4235 53 287 98 514 325 612 612 56
166 53 -62 53 3745 0 3807 3 3579 -53 3745 -98 287 -325 514 -612 612 -152 52
-178 53 -1011 53 l-774 0 0 273 c0 296 -5 337 -58 445 -15 32 -54 83 -87 117
-271 270 -722 143 -820 -231 -12 -45 -15 -120 -15 -331 l0 -273 -1520 0 -1520
0 0 278 c0 303 -6 349 -58 446 -15 28 -54 78 -87 112 -115 116 -279 165 -445
133z m-390 -2211 c0 -190 4 -282 15 -331 79 -374 553 -515 820 -243 33 34 72
84 87 112 52 96 58 143 58 446 l0 278 1520 0 1520 0 0 -273 c0 -211 3 -286 15
-331 48 -184 177 -313 361 -361 255 -66 520 97 589 362 12 44 15 121 15 330
l0 273 735 0 735 0 0 -735 0 -735 -3920 0 -3920 0 0 735 0 735 685 0 685 0 0
-262z m6470 -4393 l0 -2205 -3920 0 -3920 0 0 2205 0 2205 3920 0 3920 0 0
-2205z"/>
      <path d="M1690 4401 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
      <path d="M3650 4401 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
      <path d="M5610 4401 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
      <path d="M7570 4401 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
      <path d="M1690 2931 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
      <path d="M3650 2931 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
      <path d="M5610 2931 c-84 -27 -150 -82 -191 -161 -24 -44 -24 -50 -24 -320 0
-270 0 -276 24 -320 32 -61 76 -107 136 -138 l50 -27 275 0 275 0 50 27 c60
31 104 77 136 138 24 44 24 50 24 320 0 270 0 276 -24 320 -32 61 -76 107
-136 138 l-50 27 -260 2 c-143 1 -271 -2 -285 -6z"/>
    </g>
  </svg>
</template>
