<template>
  <svg preserveAspectRatio="none" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.32371 11.3194C1.35929 11.3306 1.39842 11.3344 1.43399 11.3344C1.58695 11.3344 1.7328 11.2484 1.81462 11.1028C3.48655 8.01007 5.22962 7.8009 7.21814 7.78596V9.63859C7.21814 9.81041 7.31063 9.96729 7.45648 10.0457C7.60233 10.1242 7.77664 10.1092 7.90826 10.0121L13.3438 5.91091C13.4576 5.825 13.5252 5.6868 13.5252 5.5374C13.5252 5.38799 13.4576 5.24979 13.3438 5.16388L7.90826 1.08509C7.77664 0.984242 7.60233 0.973037 7.45648 1.05148C7.31063 1.12991 7.2217 1.28679 7.2217 1.45861V3.34112C4.72448 3.56523 2.94939 4.58867 1.9498 6.38528C1.0996 7.90922 1 9.67968 1 10.8787C1 11.0841 1.13518 11.2671 1.32371 11.3194ZM7.67703 4.22636C7.90826 4.21515 8.08968 4.01345 8.08968 3.77067V2.35131L12.3406 5.54487L8.08968 8.74963V7.33027C8.08968 7.21074 8.04343 7.09122 7.96161 7.00531C7.8798 6.9194 7.76952 6.87085 7.65213 6.87085C5.73831 6.87085 3.77113 6.87085 1.96403 9.22773C2.27707 6.86711 3.47943 4.44673 7.67703 4.22636Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <path d="M15.368 6.0043H17.5878V16.8512H5.26533V10.281C5.26533 10.0271 5.06968 9.82162 4.82779 9.82162C4.58589 9.82162 4.3938 10.0308 4.3938 10.281V17.3106C4.3938 17.5646 4.58945 17.77 4.83134 17.77H18.0218C18.2636 17.77 18.4593 17.5646 18.4593 17.3106V5.54487C18.4593 5.29088 18.2636 5.08545 18.0218 5.08545H15.3645C15.1226 5.08545 14.9269 5.29088 14.9269 5.54487C14.9305 5.79886 15.1261 6.0043 15.368 6.0043Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <g opacity="0.2">
      <path d="M6.26585 14.6287C6.30142 14.6399 6.34056 14.6437 6.37613 14.6437C6.52909 14.6437 6.67494 14.5578 6.75676 14.4121C8.42868 11.3194 10.1718 11.1102 12.1603 11.0953V12.9479C12.1603 13.1197 12.2528 13.2766 12.3986 13.3551C12.5445 13.4335 12.7188 13.4185 12.8504 13.3214L18.2859 9.22024C18.3998 9.13433 18.4674 8.99613 18.4674 8.84672C18.4674 8.69732 18.3998 8.55911 18.2859 8.47321L12.8504 4.39442C12.7188 4.29357 12.5445 4.28236 12.3986 4.3608C12.2528 4.43924 12.1638 4.59612 12.1638 4.76793V6.65045C9.66662 6.87456 7.89153 7.89799 6.89193 9.6946C6.04174 11.2185 5.94214 12.989 5.94214 14.188C5.94214 14.3934 6.07732 14.5764 6.26585 14.6287ZM12.6192 7.53568C12.8504 7.52448 13.0318 7.32278 13.0318 7.07999V5.66063L17.2828 8.85419L13.0318 12.059V10.6396C13.0318 10.5201 12.9856 10.4005 12.9038 10.3146C12.8219 10.2287 12.7117 10.1802 12.5943 10.1802C10.6804 10.1802 8.71327 10.1802 6.90616 12.5371C7.21921 10.1764 8.42157 7.75606 12.6192 7.53568Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
      <path d="M20.3102 9.31362H22.5299V20.1605H10.2075V13.5904C10.2075 13.3364 10.0118 13.1309 9.76993 13.1309C9.52803 13.1309 9.33594 13.3401 9.33594 13.5904V20.6199C9.33594 20.8739 9.53159 21.0793 9.77348 21.0793H22.9639C23.2058 21.0793 23.4014 20.8739 23.4014 20.6199V8.8542C23.4014 8.60021 23.2058 8.39478 22.9639 8.39478H20.3066C20.0647 8.39478 19.8691 8.60021 19.8691 8.8542C19.8726 9.10819 20.0683 9.31362 20.3102 9.31362Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    </g>
  </svg>
</template>
