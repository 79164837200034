<template>
  <div class="helper">
    <button class="helper-button" @click="toggle">
      <icon class="helper-icon" name="question" />
    </button>
    <transition name="showHelper">
      <template v-if="isOpened">
        <div class="helper-content">
          <button @click="$router.push('/instruction')" class="helper-item">
            <div class="helper-icon-container">
              <icon name="instruction" class="helper-item-icon helper-item-icon-instruction" />
            </div>
            Видеоинструкция
          </button>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'helper',
  data () {
    return {
      isOpened: false
    }
  },
  watch: {
    $route: function (to, from) {
      this.isOpened = false
    }
  },
  methods: {
    toggle () {
      this.isOpened = !this.isOpened
    }
  }
}
</script>

<style lang="scss" src="./helper.scss" scoped />
