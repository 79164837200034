<template>
  <div class="activity-header">
    <adminNav>
      <router-link to="/private/clients">
        Клиенты
      </router-link>
      <router-link to="/private/activity/settings">
        Виды деятельности
      </router-link>
      <router-link to="/private/logs">
        Просмотр логов
      </router-link>
      <router-link to="/private/other/settings">
        Прочие настройки
      </router-link>
    </adminNav>
  </div>
  <template v-if="!showClient">
    <div class="companies-search">
      <div class="companies-search__label">Поиск предпринимателя <span>по почте, имени пользователя и названию компании</span> (минимум 3 символа)</div>
      <textInput placeholder="Поисковый запрос" autocomplete="off" @onChange="onSearch" />
    </div>
    <div class="companies-list">
      <div v-for="item in clients" :key="item.id" class="companies-item" @click="getLogs('choose', item)">
        <div class="companies-item__row">
          <span>Дата создания: {{ item.created }}</span>
          <div class="companies-item__large">{{ item.company || '&mdash;' }}</div>
        </div>
        <div class="companies-item__row">
          <span>Пользователь</span>
          <div>{{ item.name }}</div>
        </div>
        <div>
          <span>Телефон</span>
          <div>{{ item.phone }}</div>
        </div>
        <div>
          <span>Почта</span>
          <div>{{ item.email }}</div>
        </div>
      </div>
      <div class="companies-empty" v-if="!clients.length">Ничего не найдено</div>
    </div>
  </template>

  <template v-if="showClient">
    <div class="logs-heading">
      <div class="logs-heading__title">
        <div>{{companyData.company || '&mdash;'}}</div>
        <span>{{companyData.phone}}, {{companyData.email}}, {{companyData.name}}</span>
      </div>
      <xButton class="logs-heading__back" @click="onClear">
        <icon class="xbutton-icon" name="search"/>
        Обратно к поиску
      </xButton>
    </div>
    <div class="filters">
      <xFilter
        :hideExcel="true"
        :hideResult="true"
        @reset="onReset"
      >
        <xFilterItem
          v-for="item in filters"
          :key="item.id"
          :inputNumber="item.number"
          :item="item"
          @logChange="filtering"
        />
      </xFilter>
    </div>
    <div class="logs"><xTable v-bind="tableData" v-if="tableData.data.length" /></div>
    <div class="logs-empty" v-if="!tableData.data.length">Нет логов по указанным данным</div>
  </template>
</template>

<script>
import debounce from 'lodash.debounce'
import textInput from '@/components/textInput'
import xTable from '@/components/xTable'
import adminNav from '@/components/adminNav'
import Icon from '@/components/icons/icon'
import xButton from '@/components/xButton'
import xFilter from '@/components/xFilter'
import xFilterItem from '@/components/xFilterItem'

const searchAction = debounce((q, callback) => {
  const searchParams = (new URLSearchParams({
    q
  }))

  fetch(window.privateHost + 'users/searchUser?' + searchParams.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    }
  }).then(async response => {
    const data = await response.json()
    callback(data.data || [])
  })
}, 500)

export default {
  name: 'privateLogs',
  components: {
    textInput,
    xTable,
    adminNav,
    Icon,
    xFilter,
    xFilterItem,
    xButton
  },
  data () {
    return {
      filters: [
        {
          name: 'Профиль пользователя',
          number: 8,
          type: 'checkbox',
          code: 'profile',
          text1: 'Регистрация',
          text2: 'Логин',
          text3: 'Логаут',
          text4: 'Отравлено письмо для изменения пароля',
          text5: 'Пароль сброшен',
          text6: 'Пароль изменен пользователем вручную',
          text7: 'Логотип компании загружен',
          text8: 'Внесены изменения в профиль',
          value1: '1',
          value2: '2',
          value3: '3',
          value4: '4',
          value5: '5',
          value6: '6',
          value7: '7',
          value8: '8',
          check1: false,
          check2: false,
          check3: false,
          check4: false,
          check5: false,
          check6: false,
          check7: false,
          check8: false
        },
        {
          name: 'Параметры активности',
          number: 1,
          type: 'checkbox',
          code: 'activity',
          text1: 'Внесены изменения в параметры',
          value1: '9',
          check1: false
        },
        {
          name: 'Филиалы',
          number: 3,
          type: 'checkbox',
          code: 'office',
          text1: 'Создание',
          text2: 'Изменение',
          text3: 'Удаление',
          value1: '10',
          value2: '11',
          value3: '12',
          check1: false,
          check2: false,
          check3: false
        },
        {
          name: 'Интеграции',
          number: 1,
          type: 'checkbox',
          code: 'integration',
          text1: 'Создание',
          value1: '13',
          check1: false
        },
        {
          name: 'Кассиры',
          number: 3,
          type: 'checkbox',
          code: 'cashier',
          text1: 'Создание',
          text2: 'Изменение',
          text3: 'Удаление',
          value1: '16',
          value2: '17',
          value3: '18',
          check1: false,
          check2: false,
          check3: false
        },
        {
          name: 'Клиенты',
          number: 7,
          type: 'checkbox',
          code: 'client',
          text1: 'Создан новый диалог',
          text2: 'Отправлено сообщение',
          text3: 'Зарегистрирована покупка',
          text4: 'Отменена покупка',
          text5: 'Изменен статус',
          text6: 'Начислены бонусы',
          text7: 'Списаны бонусы',
          value1: '14',
          value2: '15',
          value3: '19',
          value4: '20',
          value5: '21',
          value6: '22',
          value7: '23',
          check1: false,
          check2: false,
          check3: false,
          check4: false,
          check5: false,
          check6: false,
          check7: false
        },
        {
          name: 'Дата операции',
          type: 'date',
          code: 'operation_date',
          valueFrom: '',
          valueTo: ''
        }
      ],
      isFilterOpened: false,
      logsQuery: {
        user_id: null,
        operation_ids: [],
        created_at_to: null,
        created_at_from: null,
        page: 1,
        pages: null
      },
      showClient: false,
      companyData: null,
      clients: [],
      tableData: {
        theads: 0,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: [],
        align: true,
        privateLogs: true,
        optionsX: [
          { name: 'Дата и время', value: 'logsDate', id: 0 },
          { name: 'Автор', value: 'logsAuthor', id: 1 },
          { name: 'Событие', value: 'logsAction', id: 2 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Дата и время', 'Автор', 'Событие'],
        namesForHeaderX: []
      }
    }
  },
  emits: ['titleName'],
  methods: {
    onResetFilters () {
      this.filters.forEach(item => {
        if (item.code !== 'operation_date') {
          for (let i = 1; i <= item.number; i++) {
            item[`check${i}`] = false
          }
        } else {
          item.valueFrom = ''
          item.valueTo = ''
        }
      })
    },
    onReset () {
      this.onResetFilters()

      this.logsQuery.operation_ids = []
      this.logsQuery.created_at_from = null
      this.logsQuery.created_at_to = null

      this.getLogs('filter', this.companyData)
    },
    onClear () {
      this.onResetFilters()

      this.logsQuery = {
        user_id: null,
        operation_ids: [],
        created_at_to: null,
        created_at_from: null,
        page: 1,
        pages: null
      }
      this.tableData.data = []
      this.clients = []
      this.companyData = null
      this.showClient = false
    },
    getLogs (type, company) {
      let query = `user_id=${company.id}`

      if (type === 'choose' || type === 'filter') query += '&page=1'

      if (type === 'scroll') {
        this.logsQuery.page += 1
        if (type === 'scroll') query += `&page=${this.logsQuery.page}`
      }

      if (type === 'filter' || type === 'scroll') {
        if (this.logsQuery.operation_ids.length) query += `&operation_ids=${this.logsQuery.operation_ids.join(',')}`
        if (this.logsQuery.created_at_from) query += `&created_at_from=${this.logsQuery.created_at_from}`
        if (this.logsQuery.created_at_to) query += `&created_at_to=${this.logsQuery.created_at_to}`
      }

      fetch(`${window.host}admin_data/logs?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        const prevData = type === 'filter' || type === 'choose' ? [] : this.tableData.data
        const nextData = data.data.log_data.map(el => {
          return {
            id: el.id,
            logsDate: new Date(el.created_at).toLocaleDateString('ru-ru', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
            logsAuthor: `${el.user_name}<br /><a href="mailto:${el.user_email}">${el.user_email}</a>`,
            logsAction: el.operation_name
          }
        })

        this.tableData.data = [...prevData, ...nextData]

        if (type === 'choose') {
          this.companyData = company
          this.showClient = true
          this.logsQuery.pages = data.data.paginate.last_page
        }
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    onSearch (q) {
      if (q.length >= 3) {
        searchAction(q, res => {
          this.clients = res.map(el => {
            return {
              id: el.id,
              name: el.name,
              email: el.email,
              phone: el.phone,
              created: new Date(el.created_at).toLocaleDateString('ru-ru', { year: 'numeric', month: 'numeric', day: 'numeric' }),
              company: el.company
            }
          })
        })
      } else {
        this.clients = []
      }
    },
    onScrollGet () {
      document.addEventListener('scroll', () => {
        if (document.documentElement.scrollHeight <= window.innerHeight + 1 + window.scrollY) {
          if (this.logsQuery.pages > this.logsQuery.page) this.getLogs('scroll', this.companyData)
        }
      })
    },
    filtering (value, name, direct) {
      const filtersGroupIndex = this.filters.findIndex(el => el.name === name)

      if (name !== 'Дата операции') {
        const filterValueKey = `value${direct.replace(/\D/gmu, '')}`
        const operationId = this.filters[filtersGroupIndex][filterValueKey]

        if (operationId) {
          if (value) {
            this.logsQuery.operation_ids.push(operationId)
          } else {
            this.logsQuery.operation_ids = this.logsQuery.operation_ids.filter(item => {
              return item.toString() !== operationId.toString()
            })
          }

          this.filters[filtersGroupIndex][direct] = value
        }
      } else {
        if (direct === 'from') {
          this.logsQuery.created_at_from = value || null
          this.filters[filtersGroupIndex].valueFrom = value || ''
        }

        if (direct === 'to') {
          this.logsQuery.created_at_to = value || null
          this.filters[filtersGroupIndex].valueTo = value || ''
        }
      }

      this.getLogs('filter', this.companyData)
    }
  },
  created () {
    this.$emit('titleName', 'Кабинет администратора системы')
    this.onScrollGet()
  }
}
</script>

<style lang="scss" src="../vPrivate/vPrivate.scss" scoped/>
<style lang="scss" src="./vPrivateLogs.scss" scoped />
