export default {
  namespaced: true,
  state () {
    return {
      isShow: false,
      text: '',
      buttonText: 'Ок',
      title: 'Ошибка',
      rollBackButtonText: false,
      callback: () => {},
      errorCallback: () => {},
      blockClose: false,
      useFullWindow: false,
      useMiddleTitle: false
    }
  },
  mutations: {
    show (state, props) {
      const {
        text,
        callback = false,
        buttonText = false,
        title = false,
        rollBackButtonText = false,
        errorCallback = false,
        blockClose = false,
        useMiddleTitle = false,
        useFullWindow = false
      } = props
      state.isShow = true
      state.text = text
      if (callback) {
        state.callback = callback
      }
      if (buttonText) {
        state.buttonText = buttonText
      }
      if (title) {
        state.title = title
      }
      if (rollBackButtonText) {
        state.rollBackButtonText = rollBackButtonText
      }
      if (errorCallback) {
        state.errorCallback = errorCallback
      }
      if (blockClose) {
        state.blockClose = blockClose
      }
      if (useFullWindow) {
        state.useFullWindow = useFullWindow
      }
      if (useMiddleTitle) {
        state.useMiddleTitle = useMiddleTitle
      }
    },
    action (state) {
      state.callback()
      this.commit('popupStore/close')
    },
    errorAction (state) {
      state.errorCallback()
      this.commit('popupStore/close')
    },
    close (state) {
      state.isShow = false
      setTimeout(() => {
        state.text = ''
        state.buttonText = 'Ок'
        state.title = 'Ошибка'
        state.rollBackButtonText = false
        state.callback = () => {}
        state.errorCallback = () => {}
      }, 300)
    }
  }
}
