<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1808_13376)">
      <path d="M9.00024 9.62811C7.75976 9.62811 6.75024 10.6376 6.75024 11.8781C6.75024 12.8546 7.37951 13.6796 8.25026 13.9901V15.2531C8.25026 15.6678 8.58624 16.0031 9.00024 16.0031C9.41424 16.0031 9.75023 15.6678 9.75023 15.2531V13.9901C10.621 13.6796 11.2502 12.8546 11.2502 11.8781C11.2502 10.6376 10.2407 9.62811 9.00024 9.62811ZM9.00024 12.6281C8.58698 12.6281 8.25026 12.2921 8.25026 11.8781C8.25026 11.4641 8.58698 11.1281 9.00024 11.1281C9.41351 11.1281 9.75023 11.4641 9.75023 11.8781C9.75023 12.2921 9.41351 12.6281 9.00024 12.6281Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.06765 0.572787C11.4164 0.640123 12.8264 1.80306 13.5708 3.25036C14.1274 4.33235 14.3016 5.54472 14.2907 6.52802C15.5297 6.79522 16.4582 7.89728 16.4582 9.2162V15.9365C16.4582 17.4553 15.227 18.6865 13.7082 18.6865H4.29199C2.77321 18.6865 1.54199 17.4553 1.54199 15.9365V9.2162C1.54199 7.69742 2.77321 6.4662 4.29199 6.4662H12.7913C12.7944 5.68319 12.6506 4.74063 12.237 3.9365C11.7275 2.94608 10.7943 2.12132 9.01942 2.07202L9.00552 2.07164C8.77387 2.0609 8.29508 2.10132 7.78099 2.27814C7.27036 2.45376 6.78761 2.74176 6.46251 3.17778C6.21491 3.50985 5.745 3.57833 5.41293 3.33073C5.08087 3.08314 5.01239 2.61323 5.25998 2.28116C5.82083 1.52897 6.59969 1.09819 7.29313 0.859689C7.97916 0.623741 8.64319 0.554256 9.06765 0.572787ZM4.29199 7.9662C3.60164 7.9662 3.04199 8.52585 3.04199 9.2162V15.9365C3.04199 16.6269 3.60164 17.1865 4.29199 17.1865H13.7082C14.3986 17.1865 14.9582 16.6269 14.9582 15.9365V9.2162C14.9582 8.52585 14.3986 7.9662 13.7082 7.9662H4.29199Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1808_13376">
        <rect width="18" height="18" fill="white" transform="translate(0 0.569336)"/>
      </clipPath>
    </defs>
  </svg>
</template>
