<template>
  <svg preserveAspectRatio="none" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9315 16.9309L18.4595 5.6001C18.413 4.48474 17.467 3.57734 16.3507 3.57734H14.8206V2.34531C14.8206 1.05209 13.7685 0 12.4753 0C11.1821 0 10.13 1.05213 10.13 2.34531V3.57734H8.59984C7.48355 3.57734 6.54313 4.35156 6.50342 5.30323L6.47596 5.96221H2.63759C1.52364 5.96221 0.572228 6.86731 0.516675 7.97974L0.0692472 16.9269C0.0417863 17.4767 0.232862 17.9962 0.607259 18.3897C0.981618 18.7833 1.49094 19 2.04135 19H10.9847H16.9469C17.4965 19 18.0065 18.7838 18.3831 18.3912C18.7596 17.9986 18.9544 17.48 18.9315 16.9309ZM11.2432 2.34531C11.2432 1.66595 11.7959 1.11328 12.4752 1.11328C13.1546 1.11328 13.7073 1.66599 13.7073 2.34531V3.57734H11.2432V2.34531ZM1.41383 17.6224C1.25166 17.4519 1.16902 17.2247 1.18112 16.9825L1.62855 8.03533C1.65452 7.51506 2.11661 7.07553 2.63755 7.07553H10.3884C10.9094 7.07553 11.3715 7.51506 11.3974 8.03537L11.8449 16.9824C11.857 17.2247 11.7743 17.452 11.6122 17.6224C11.45 17.7929 11.2272 17.8867 10.9847 17.8867H2.04131C1.79884 17.8867 1.576 17.7929 1.41383 17.6224ZM17.5797 17.6206C17.4151 17.7922 17.1903 17.8867 16.9469 17.8867H12.7664C12.9073 17.5932 12.9737 17.2659 12.9568 16.9269L12.5094 7.97981C12.4538 6.86731 11.5024 5.96225 10.3885 5.96225H7.59017L7.6157 5.34961C7.62872 5.03793 8.03607 4.69063 8.5998 4.69063H16.3507C16.8693 4.69063 17.3256 5.12833 17.3472 5.64641L17.8193 16.9772C17.8294 17.2205 17.7443 17.4489 17.5797 17.6206Z" fill="currentColor"/>
    <path d="M8.30167 7.75098C7.99426 7.75098 7.74503 8.0002 7.74503 8.30762V10.0963C7.74503 10.7756 7.19236 11.3283 6.51304 11.3283C5.83368 11.3283 5.28101 10.7756 5.28101 10.0963V8.30762C5.28101 8.0002 5.03178 7.75098 4.72437 7.75098C4.41695 7.75098 4.16772 8.0002 4.16772 8.30762V10.0963C4.16772 11.3895 5.21985 12.4416 6.51304 12.4416C7.80622 12.4416 8.85831 11.3894 8.85831 10.0963V8.30762C8.85831 8.0002 8.60909 7.75098 8.30167 7.75098Z" fill="currentColor"/>
    <g opacity="0.2">
      <path d="M24.9315 20.9309L24.4595 9.6001C24.413 8.48474 23.467 7.57734 22.3507 7.57734H20.8206V6.34531C20.8206 5.05209 19.7685 4 18.4753 4C17.1821 4 16.13 5.05213 16.13 6.34531V7.57734H14.5998C13.4836 7.57734 12.5431 8.35156 12.5034 9.30323L12.476 9.96221H8.63759C7.52364 9.96221 6.57223 10.8673 6.51667 11.9797L6.06925 20.9269C6.04179 21.4767 6.23286 21.9962 6.60726 22.3897C6.98162 22.7833 7.49094 23 8.04135 23H16.9847H22.9469C23.4965 23 24.0065 22.7838 24.3831 22.3912C24.7596 21.9986 24.9544 21.48 24.9315 20.9309ZM17.2432 6.34531C17.2432 5.66595 17.7959 5.11328 18.4752 5.11328C19.1546 5.11328 19.7073 5.66599 19.7073 6.34531V7.57734H17.2432V6.34531ZM7.41383 21.6224C7.25166 21.4519 7.16902 21.2247 7.18112 20.9825L7.62855 12.0353C7.65452 11.5151 8.11661 11.0755 8.63755 11.0755H16.3884C16.9094 11.0755 17.3715 11.5151 17.3974 12.0354L17.8449 20.9824C17.857 21.2247 17.7743 21.452 17.6122 21.6224C17.45 21.7929 17.2272 21.8867 16.9847 21.8867H8.04131C7.79884 21.8867 7.576 21.7929 7.41383 21.6224ZM23.5797 21.6206C23.4151 21.7922 23.1903 21.8867 22.9469 21.8867H18.7664C18.9073 21.5932 18.9737 21.2659 18.9568 20.9269L18.5094 11.9798C18.4538 10.8673 17.5024 9.96225 16.3885 9.96225H13.5902L13.6157 9.34961C13.6287 9.03793 14.0361 8.69063 14.5998 8.69063H22.3507C22.8693 8.69063 23.3256 9.12833 23.3472 9.64641L23.8193 20.9772C23.8294 21.2205 23.7443 21.4489 23.5797 21.6206Z" fill="currentColor"/>
      <path d="M14.3017 11.751C13.9943 11.751 13.745 12.0002 13.745 12.3076V14.0963C13.745 14.7756 13.1924 15.3283 12.513 15.3283C11.8337 15.3283 11.281 14.7756 11.281 14.0963V12.3076C11.281 12.0002 11.0318 11.751 10.7244 11.751C10.417 11.751 10.1677 12.0002 10.1677 12.3076V14.0963C10.1677 15.3895 11.2198 16.4416 12.513 16.4416C13.8062 16.4416 14.8583 15.3894 14.8583 14.0963V12.3076C14.8583 12.0002 14.6091 11.751 14.3017 11.751Z" fill="currentColor"/>
    </g>
  </svg>
</template>
