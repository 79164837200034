<template>
    <div v-show="this.$store.state.animateStore.isShowSave" class="save-popup">
        <div class="save-popup-text">{{ this.$store.state.animateStore.saveText || 'Сохранено' }}</div>
        <div class="save-popup-close" @click="this.$store.commit('animateStore/closeSave')">
            <icon name="close"/>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/icons/icon'
export default {
  name: 'savePopup',
  components: {
    Icon
  }
}
</script>

<style lang="scss" src="./savePopup.scss" scoped />
