<template>
  <svg preserveAspectRatio="none" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6612 10.3057C12.4007 10.6336 12.1081 10.9364 11.7721 11.2097C13.6904 12.31 14.9854 14.3787 14.9854 16.7443C14.9854 18.0225 12.1849 19.0237 8.60971 19.0237C5.03456 19.0237 2.234 18.0225 2.234 16.7443C2.234 14.3787 3.52906 12.31 5.44734 11.2097C5.11132 10.9364 4.81872 10.6337 4.55823 10.3057C2.42199 11.6547 1 14.0364 1 16.7443C1 17.5646 1.44916 18.7078 3.58906 19.4956C4.92387 19.9871 6.70691 20.2577 8.60975 20.2577C10.5126 20.2577 12.2956 19.9871 13.6304 19.4956C15.7703 18.7078 16.2195 17.5646 16.2195 16.7443C16.2194 14.0364 14.7974 11.6547 12.6612 10.3057Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <path d="M8.60981 11.1189C11.0081 11.1189 12.8548 8.8007 12.8548 6.05947C12.8548 3.31697 11.0071 1 8.60981 1C6.21156 1 4.36481 3.31819 4.36481 6.05942C4.36481 8.80192 6.21254 11.1189 8.60981 11.1189ZM8.60981 2.234C10.2701 2.234 11.6208 3.95007 11.6208 6.05942C11.6208 8.16878 10.2701 9.88485 8.60981 9.88485C6.94957 9.88485 5.59881 8.16878 5.59881 6.05942C5.59881 3.95007 6.94957 2.234 8.60981 2.234Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <g opacity="0.2">
      <path d="M21.0865 15.1201C20.8261 15.4481 20.5335 15.7509 20.1974 16.0241C22.1157 17.1245 23.4108 19.1932 23.4108 21.5587C23.4108 22.8369 20.6102 23.8382 17.0351 23.8382C13.4599 23.8382 10.6594 22.8369 10.6594 21.5587C10.6594 19.1932 11.9544 17.1245 13.8727 16.0241C13.5367 15.7509 13.2441 15.4481 12.9836 15.1201C10.8473 16.4691 9.42535 18.8508 9.42535 21.5587C9.42535 22.379 9.87451 23.5222 12.0144 24.3101C13.3492 24.8016 15.1323 25.0722 17.0351 25.0722C18.9379 25.0722 20.721 24.8016 22.0558 24.3101C24.1957 23.5222 24.6449 22.379 24.6449 21.5587C24.6448 18.8508 23.2228 16.4691 21.0865 15.1201Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
      <path d="M17.0352 15.9333C19.4334 15.9333 21.2802 13.6151 21.2802 10.8739C21.2802 8.13142 19.4325 5.81445 17.0352 5.81445C14.6369 5.81445 12.7902 8.13265 12.7902 10.8739C12.7902 13.6164 14.6379 15.9333 17.0352 15.9333ZM17.0352 7.04845C18.6954 7.04845 20.0462 8.76452 20.0462 10.8739C20.0462 12.9832 18.6954 14.6993 17.0352 14.6993C15.3749 14.6993 14.0242 12.9832 14.0242 10.8739C14.0242 8.76452 15.3749 7.04845 17.0352 7.04845Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    </g>
  </svg>
</template>
