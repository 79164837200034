<template>
  <button :class="['x-button', size, additionalClass, disabled ? 'disabled' : ''].join(' ')" @click="clickOnButton">
    <span>
      <slot>Далее</slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'xButton',
  props: {
    size: {
      validator (value) {
        return ['extra-small', 'small', 'middle', 'big', 'extra-big'].includes(value)
      },
      default: ''
    },
    additionalClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickOnButton () {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="scss" src="./xButton.scss" scoped />
