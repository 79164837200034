<template>
  <div class="activity-header">
    <adminNav>
      <router-link to="/private/clients">
        Клиенты
      </router-link>
      <router-link to="/private/activity/settings">
        Виды деятельности
      </router-link>
      <router-link to="/private/logs">
        Просмотр логов
      </router-link>
      <router-link to="/private/other/settings">
        Прочие настройки
      </router-link>
    </adminNav>
  </div>
  <div class="companies-search">
    <textInput placeholder="Введите логин аккаунта, название компании или имя/фамилию клиента" autocomplete="off" @onChange="onSearch">
      <template v-slot:before>
        <button class="search-button">
          <icon class="search-icon" name="search" />
        </button>
      </template>
    </textInput>
  </div>
  <div class="private-table">
    <div class="msg-table-wrapper">
      <xTable v-bind="tableData">
        <template v-slot="{ records }">
          <td class="table-action">
            <button @click="openUpgrade(records)">
              <icon name="prolong" />
            </button>
            <button v-if="records.active === 1" @click="openLock(records)">
              <icon name="blocker" class="table-icon" />
            </button>
          </td>
        </template>
      </xTable>
    </div>
  </div>
  <pop-up :open="popLock" @close="popLock = !popLock" sizer="pop-verysmall" :privateView="true">
    <h2 class="x-pop-title">Блокировка компании</h2>
    <p class="x-pop-desc-black">
      После блокировки компании оставшиеся дни до конца подписки обнулятся. Для разблокировки компании начислите дни подписки.
      <br>
      Подтвердите блокировку компании.
    </p>
    <div class="x-pop-footer">
      <xButton @onClick="sendBlock">Заблокировать</xButton>
      <button @click="popLock = false" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>
  <pop-up :open="popUpgrade" @close="popUpgrade = !popUpgrade" sizer="pop-verysmall" :privateView="true">
    <h2 class="x-pop-title">Продление подписки</h2>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Аккаунт
      </div>
      <div class="x-pop-item__text">
        {{ popupRow.companyLogin }}
      </div>
    </div>
    <div class="x-pop-item">
      <div class="x-pop-item__desc">
        Название
      </div>
      <div class="x-pop-item__text">
        {{ popupRow.companyName }}
      </div>
    </div>
    <div class="x-pop-spacer"></div>
    <h2 class="x-pop-title">Продлить подписку</h2>
    <div class="popUp-radio">
      <label class="label">
        <input class="radio" type="radio" name="prolong" value="days" v-model="upgradeType"/>
        <span class="checkmark"></span>
      </label>
      <div class="popUp-radio__text">на</div>
      <div class="popUp-radio__smallinput">
        <text-input :default-value="upgradeDays" @onChange="setUpgradeDays"/>
      </div>
      <div class="popUp-radio__text">дней</div>
    </div>
    <div class="popUp-radio">
      <label class="label">
        <input class="radio" type="radio" name="prolong" value="date" v-model="upgradeType"/>
        <span class="checkmark"></span>
      </label>
      <div class="popUp-radio__text">до</div>
      <text-input type="date" :default-value="upgradeDate" @onChange="setUpgradeDate"/>
    </div>
    <xButton @onClick="sendUpgrade">Сохранить</xButton>
  </pop-up>
</template>

<script>
import debounce from 'lodash.debounce'
import textInput from '@/components/textInput'
import xTable from '@/components/xTable'
// import vPrivateCompanies from '@/views/vPrivateCompanies/vPrivateCompanies.json'
import xButton from '@/components/xButton'
import popUp from '@/components/popUp'
import adminNav from '@/components/adminNav'

const searchAction = debounce((q, callback) => {
  const searchParams = (new URLSearchParams({
    q
  }))

  fetch(window.privateHost + 'users?' + searchParams.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    }
  }).then(async response => {
    const data = await response.json()

    if (!response.ok || !data.success) {
      const error = (data && data.message) || response.status
      return Promise.reject(error)
    }

    callback(data.data)
  }).catch(err => {
    this.$store.commit('techStore/checkError', { err })
  })
}, 500)

export default {
  name: 'privateCompanies',
  components: {
    textInput,
    xTable,
    xButton,
    adminNav,
    popUp
  },
  data () {
    return {
      popLock: false,
      popUpgrade: false,
      upgradeType: 'days',
      upgradeDays: '5',
      upgradeDate: 'дд.мм.гггг',
      popupRow: {
        id: '─',
        companyReg: '─',
        companyLogin: '─',
        companyName: '─',
        clientName: '─',
        activeUntil: '─',
        active: '─'
      },
      tableData: {
        theads: 1,
        privateCompanies: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: []/* vPrivateCompanies */,
        optionsX: [
          { name: 'Дата регистрации', value: 'companyReg', id: 0 },
          { name: 'Логин', value: 'companyLogin', id: 1 },
          { name: 'Название компании', value: 'companyName', id: 2 },
          { name: 'Вид деятельности', value: 'activity', id: 2 },
          { name: 'ФИО клиента', value: 'clientName', id: 3 },
          { name: 'Активен до', value: 'activeUntil', id: 4 }
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Дата регистрации', 'Логин', 'Название компании', 'Вид деятельности', 'ФИО клиента', 'Активен до'],
        namesForHeaderX: []
      }
    }
  },
  emits: ['titleName'],
  methods: {
    openLock (records) {
      this.popLock = true
      this.popupRow = records
    },
    openUpgrade (records) {
      this.popUpgrade = true
      this.popupRow = records
    },
    setUpgradeDays (value) {
      this.upgradeDays = value
    },
    setUpgradeDate (value) {
      this.upgradeDate = value
    },
    sendUpgrade () {
      const body = {}
      if (this.upgradeType === 'days') {
        body.subscriptionDays = parseInt(this.upgradeDays)
      } else {
        const arDate = this.upgradeDate.split('-')
        body.subscriptionDate = [arDate[2], arDate[1], arDate[0]].join('-')
      }

      fetch(window.privateHost + 'users/' + this.popupRow.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(body)
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.popUpgrade = false
        const changedRowI = this.tableData.data.findIndex(el => el.id === data.data.id)
        this.tableData.data[changedRowI].activeUntil = data.data.subscription ? data.data.subscription : '─'
        this.tableData.data[changedRowI].active = data.data.active ? data.data.active : 0
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    sendBlock () {
      fetch(window.privateHost + 'users/' + this.popupRow.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify({
          active: 0
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.popLock = false
        const changedRowI = this.tableData.data.findIndex(el => el.id === data.data.id)
        this.tableData.data[changedRowI].active = 0
        this.tableData.data[changedRowI].activeUntil = data.data.subscription
      }).catch(err => {
        this.$store.commit('techStore/checkError', { err })
      })
    },
    onSearch (q) {
      searchAction(q, res => {
        this.tableData.data.splice(0, this.tableData.data.length)
        res.map(el => {
          this.tableData.data.push({
            id: el.id,
            companyReg: el.created_at ? el.created_at : '─',
            companyLogin: el.email ? el.email : '─',
            companyName: el.company ? el.company : '─',
            activity: el.activity ? el.activity : '─',
            clientName: el.name ? el.name : '─',
            activeUntil: el.subscription ? el.subscription : '─',
            active: el.active ? el.active : 0
          })

          return this.tableData.data
        })
      })
    }
  },
  created () {
    this.$emit('titleName', 'Кабинет администратора системы')

    fetch(window.host + 'users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      this.tableData.data = data.data.map(el => {
        return {
          id: el.id,
          companyReg: el.created_at ? el.created_at : '─',
          companyLogin: el.email ? el.email : '─',
          companyName: el.company ? el.company : '─',
          activity: el.activity ? el.activity : '─',
          clientName: el.name ? el.name : '─',
          activeUntil: el.subscription ? el.subscription : '─',
          active: el.active ? el.active : 0
        }
      })
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style lang="scss" src="../vPrivate/vPrivate.scss" scoped/>
<style lang="scss" src="./vPrivateCompanies.scss" scoped />
