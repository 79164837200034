<template>
  <transition name="popMotion">
    <div v-show="open" :class="getCustomClass">
      <div @click="$emit('close')" v-if="center" class="pop-bg"></div>
      <div class="pop-container" :class="[sizer]">
        <button @click="$emit('close')" v-if="iconClose" class="close">
          <icon name="close"/>
        </button>
        <div class="pop-content">
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'popUp',
  props: {
    open: {
      type: Boolean
    },
    center: {
      type: Boolean,
      default: true
    },
    sizer: {
      validator (value) {
        return ['pop-small', 'pop-verysmall', 'pop-customsize'].includes(value)
      }
    },
    iconClose: {
      type: Boolean,
      default: true
    },
    additionalClass: {
      type: String,
      default: ''
    },
    blur: {
      type: Boolean
    },
    privateView: {
      type: Boolean
    }
  },
  computed: {
    getCustomClass () {
      return ['pop-wrapper', this.center ? 'x-pop-center' : '', blur ? 'x-pop-blur' : '', this.privateView ? 'x-pop-private' : '', this.additionalClass].join(' ')
    }
  }
}
</script>

<style lang="scss" src="./popUp.scss" />
