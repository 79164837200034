<template>
  <label class="label" :class="[color]">
    <input class="checkbox"  type="checkbox" :checked="checked"
           @change="$emit('isChecked', $event.target.checked, name)"/>
    <span class="checkmark">
      <icon class="icon" name="checkboxIcon" />
    </span>
    <span v-if="name" class="label-text" :class="fontRegular && 'label-text-f-r'">
      {{ name }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'checkboxInput',
  props: {
    checked: {
      type: Boolean
    },
    name: {
      type: String,
      default: ''
    },
    fontRegular: {
      type: Boolean,
      default: false
    },
    color: {
      validator (value) {
        return ['white-bg'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" src="./checkboxInput.scss" scoped/>
