<template>
  <svg preserveAspectRatio="none" viewBox="0 0 224 462" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.07353 276.782C8.02603 276.581 8.00137 276.374 8.00092 276.168C7.98479 269.501 7.86781 213.516 8.55035 211.068C9.16655 208.857 30.1181 171.232 30.1181 171.232L43.8109 186.48L23.4321 220.389C23.1054 222.447 18.3734 260.723 18.3734 260.723C18.3734 260.723 17.318 267.717 16.9487 271.882C17.4036 272.833 17.8463 273.725 18.2322 274.46C19.1769 276.011 20.2319 277.253 21.1819 278.298C22.3772 279.607 24.4288 282.526 24.7268 283.333C25.0732 284.27 25.7239 286.225 26.0999 287.408C26.4759 288.592 26.9662 289.959 27.0908 290.61C27.1661 291.004 27.6564 291.762 28.0283 292.291C28.5823 293.08 27.88 294.147 26.9411 293.936C26.0565 293.739 25.2785 292.694 24.7985 292.04C23.4424 290.202 22.4654 286.696 22.1961 286.911C21.9272 287.13 21.5212 293.291 21.4293 293.99C21.3379 294.694 20.827 301.85 20.2691 303.204C19.7111 304.558 18.7798 304.437 18.4572 303.657C18.1798 302.984 18.1565 293.044 18.1565 293.044C18.1565 293.044 18.1507 291.775 17.6424 292.224C17.1338 292.672 17.1378 301.697 16.8877 303.042C16.6381 304.387 15.8404 305.168 14.9975 304.19C13.9725 303.002 14.5605 293.371 14.5605 293.371C14.5605 293.371 14.6129 292.161 14.1173 292.322C13.6212 292.484 13.7525 300.164 13.424 300.863C13.0959 301.563 12.1239 301.899 11.5386 300.845C10.8489 299.612 11.4481 291.018 11.4481 291.018C11.4481 291.018 11.3087 290.35 11.0152 290.605C10.7216 290.861 10.3672 297.416 9.77291 298.079C9.27412 298.635 8.76727 298.196 8.76727 298.196C7.48466 297.492 8.70721 291.17 8.38185 288.121C8.05694 285.068 9.12176 281.499 8.3357 277.908C8.28641 277.679 8.15599 277.128 8.07353 276.782Z" fill="#F8A492"/>
    <path d="M111.258 237.933C111.07 237.933 110.877 237.906 110.689 237.848C109.641 237.534 109.04 236.427 109.354 235.377L118.308 205.369C118.621 204.316 119.724 203.715 120.777 204.029C121.83 204.347 122.426 205.45 122.113 206.504L113.163 236.512C112.903 237.373 112.114 237.933 111.258 237.933Z" fill="#F7CC7F"/>
    <path d="M159.235 308.046H99.418V229.127H159.235V308.046Z" fill="#8F3C87"/>
    <path d="M155.123 180.489L156.212 181.462C156.772 181.964 157.614 181.942 158.094 181.417L177.414 160.165C177.893 159.636 177.83 158.802 177.27 158.3L176.181 157.327C175.621 156.825 174.779 156.847 174.299 157.372L154.979 178.624C154.5 179.153 154.562 179.987 155.123 180.489Z" fill="#13375B"/>
    <path d="M89.6124 163.224C92.1946 161.121 112.169 169.891 111.913 172.944C111.658 175.993 125.851 205.584 125.851 205.584L150.369 186.023L150.383 186.027C150.598 185.319 152.924 177.715 153.036 176.352C153.152 174.926 157.298 172.429 159.22 172.796C161.147 173.164 157.575 175.576 157.342 176.02C156.702 177.226 159.171 177.679 159.171 177.679C159.171 177.679 166.256 173.792 166.404 171.5C166.556 169.214 168.017 164.421 169.55 165.421C170.339 165.941 170.334 169.344 170.088 170.958C170.54 169.124 171.186 167.053 171.813 166.851C172.96 166.474 173.53 167.936 173.319 168.649C173.113 169.366 171.262 177.145 170.697 178.024C170.151 178.876 158.709 188.098 157.06 190.103C157.06 190.103 125.344 226.374 123.086 227.325C121.105 228.159 114.038 215.658 107.363 207.355C100.688 199.061 81.5421 169.788 89.6124 163.224Z" fill="#F7A491"/>
    <path d="M25.6161 429.946C25.6161 429.946 21.2578 434.201 21.1592 435.766C21.0602 437.326 20.7729 454.054 22.5521 455.614C24.3313 457.174 29.5576 458.282 27.8775 460.039C26.6074 461.366 16.8377 461.084 10.3628 460.725C8.47251 460.622 6.10223 445.535 5.35381 443.316C4.26615 440.097 1.62248 432.842 2.61066 430.596C3.09242 429.502 5.39683 427.256 8.64325 425.158C12.0586 422.952 15.3261 418.038 15.3261 418.038L25.6161 429.946Z" fill="#F48C7F"/>
    <path d="M70.7628 439.855L66.8576 450.907C66.5677 452.656 65.4258 456.969 65.4258 458.556C65.4258 464.519 92.3116 453.494 86.2862 450.849C85.7404 450.611 84.0298 448.701 83.8506 446.67C83.574 443.541 86.1151 439.618 86.1151 439.618L70.7628 439.855Z" fill="#F7A491"/>
    <path d="M112.94 234.593C112.94 234.593 114.903 254.351 114.903 254.647C114.903 286.615 121.235 349.056 120.03 355.064C117.094 369.725 85.4649 444.501 85.4649 444.501L68.3711 442.456L90.646 352.926L82.4493 316.892L70.8502 245.658L112.94 234.593Z" fill="#2F4B7D"/>
    <path d="M55.5748 252.068L52.3316 270.8L43.5563 347.881L10.1367 423.217C10.1367 423.217 14.8374 434.179 21.7725 435.721L74.9623 358.372L108.003 259.399L106.843 237.412L55.5748 252.068Z" fill="#355389"/>
    <path d="M119.757 187.542L96.0564 189.963C96.0564 189.963 94.9688 170.523 94.9688 161.247C94.9688 149.997 95.9269 135.507 97.2848 135.821C101.096 136.695 106.229 138.798 109.284 142.999C119.86 157.534 119.757 187.542 119.757 187.542Z" fill="#BD936C"/>
    <path d="M108.115 159.266C108.612 162.696 117.722 255.948 117.722 255.948C74.565 276.801 50.7507 259.665 50.7507 259.665C50.7507 259.665 52.1628 194.179 50.4701 185.266C49.3502 179.37 47.9542 140.184 51.7742 139.085C56.6757 137.678 62.2959 136.185 68.4217 135.548C73.6508 135.005 93.9637 134.687 97.5982 135.911C97.5982 135.911 106.027 144.842 108.115 159.266Z" fill="#BD936C"/>
    <path d="M20.9678 178.65L41.5011 192.441C41.5011 192.441 53.0742 175.211 59.2864 169.078C67.0685 161.393 56.4514 137.855 51.4375 139.186C40.2221 142.168 20.9678 178.65 20.9678 178.65Z" fill="#BD936C"/>
    <path d="M8.85341 425.055C8.85341 425.055 18.8987 453.351 22.8304 454.714C23.8741 455.077 28.8844 455.996 29.8291 458.215C30.1142 458.883 29.8291 461.112 29.8291 461.112H8.89197C8.89197 461.112 6.24383 448.643 5.19695 443.169C5.19695 443.169 -0.345348 429.785 0.270412 428.566C0.886173 427.351 8.85341 425.055 8.85341 425.055Z" fill="#2F4B7D"/>
    <path d="M63.8984 460.524L66.9772 450.795C66.9772 450.795 73.4436 453.53 76.6766 453.073C79.9091 452.62 86.2213 450.795 86.2213 450.795C99.1258 455.041 109.696 457.103 107.775 460.524H63.8984Z" fill="#2F4B7D"/>
    <path d="M74.1576 116.397L70.9824 135.82C70.9824 135.82 82.6057 160.533 89.5507 158.452C95.3785 156.704 91.7933 135.479 91.7933 135.479C91.7933 135.479 88.6172 124.082 89.5695 118.379C90.5219 112.676 74.1576 116.397 74.1576 116.397Z" fill="#F7A491"/>
    <path d="M89.5705 118.38C90.5229 112.677 74.1585 116.399 74.1585 116.399L73.8945 118.071C74.3732 119.187 74.9584 120.142 75.6688 120.878C79.3244 124.653 85.0379 126.917 89.9779 127.092C89.4836 124.016 89.1797 120.721 89.5705 118.38Z" fill="#F47458"/>
    <path d="M71.474 87.8965C71.0115 87.7351 67.7669 110.843 73.924 117.201C80.0821 123.558 92.0791 125.639 96.3827 120.631C101.143 115.093 100.903 90.2593 91.3298 83.7178C81.7559 77.1764 71.474 87.8965 71.474 87.8965Z" fill="#F7A491"/>
    <path d="M73.7313 78.9913C70.372 79.3724 65.8049 81.1569 61.089 88.6488C55.2903 97.8579 59.5729 108.067 66.3225 113.07C73.0726 118.078 72.952 123.813 72.952 123.813L74.0455 120.872C74.8334 118.608 75.1547 116.204 74.8912 113.824C74.7334 112.398 74.4148 110.856 73.7995 109.587C72.1763 106.247 77.287 104.023 78.7197 101.238C79.8235 99.0998 79.8769 94.1949 81.6157 90.6574L83.6915 87.9539C83.6915 87.9539 88.4814 84.376 93.1677 85.3848C97.854 86.3936 92.0971 64.4558 73.7313 78.9913Z" fill="#F7CC7F"/>
    <path d="M78.4049 105.354C78.4537 108.17 77.1178 110.479 75.4215 110.506C73.7252 110.533 72.3099 108.273 72.2611 105.453C72.2122 102.632 73.5482 100.323 75.2445 100.297C76.9408 100.27 78.356 102.534 78.4049 105.354Z" fill="#F7A491"/>
    <path d="M124.046 240.201C123.347 240.335 122.675 239.864 122.554 239.165C122.105 236.533 121.12 230.207 120.752 223.643C120.075 211.538 121.707 204.687 125.74 202.705C129.769 200.723 136.213 203.552 145.454 211.34C150.464 215.564 154.906 220.132 156.739 222.065C157.227 222.58 157.196 223.401 156.671 223.881H156.667C156.151 224.351 155.358 224.32 154.883 223.809C146.471 214.797 132.153 202.4 126.869 205.001C121.586 207.597 122.899 226.544 125.063 238.725C125.184 239.416 124.732 240.071 124.046 240.201Z" fill="#F7CC7F"/>
    <path d="M133.72 231.934C132.644 231.934 133.76 231.485 133.684 230.405C131.627 201.451 125.16 204.446 124.873 203.63C124.398 202.262 125.908 201.33 126.944 201.697C127.853 202.02 135.127 205.571 135.71 229.898C135.736 230.997 134.867 231.907 133.769 231.934H133.72Z" fill="#FAE0B2"/>
    <path d="M159.233 217.771L116.004 238.408L136.52 322.277L209.475 285.36L159.233 217.771Z" fill="#F47458"/>
    <path d="M123.087 227.324C123.182 227.283 123.536 227.005 123.737 226.84C122.971 217.702 123.11 208.852 125.974 205.857C125.906 205.713 125.279 204.382 124.625 203.001C121.304 205.57 119.726 212.398 120.345 223.481C120.385 224.244 120.605 224.826 120.784 225.777C121.743 226.835 122.617 227.521 123.087 227.324Z" fill="#F7A491"/>
    <path d="M215.342 274.186L159.233 217.77L209.476 285.359L211.179 277.975L215.342 274.186Z" fill="#F7A491"/>
    <path d="M88.302 110.848L97.1197 111.161C97.1197 111.161 96.7975 114.506 92.9157 114.479C88.1828 114.448 88.302 110.848 88.302 110.848Z" fill="white"/>
    <path opacity="0.3" d="M179.305 119.688C178.649 121.253 177.483 122.389 175.806 123.096C174.13 123.803 172.486 123.838 170.874 123.202C169.285 122.556 168.152 121.429 167.475 119.823C166.797 118.217 166.782 116.619 167.428 115.03C168.098 113.432 169.27 112.279 170.947 111.572C172.623 110.865 174.255 110.835 175.844 111.481C177.423 112.104 178.556 113.23 179.243 114.86C179.931 116.49 179.951 118.099 179.305 119.688ZM176.478 134.247L184.936 109.827L187.567 110.116L179.089 134.586L176.478 134.247ZM172.448 119.618C173.043 119.86 173.656 119.849 174.284 119.584C174.913 119.319 175.348 118.888 175.59 118.293C175.823 117.673 175.807 117.05 175.542 116.421C175.277 115.792 174.846 115.357 174.25 115.115C173.655 114.872 173.042 114.884 172.414 115.149C171.785 115.414 171.35 115.844 171.107 116.44C170.888 117.026 170.911 117.633 171.176 118.262C171.442 118.89 171.865 119.342 172.448 119.618ZM196.925 128.504C196.279 130.093 195.118 131.24 193.442 131.947C191.765 132.654 190.133 132.685 188.544 132.038C186.955 131.392 185.822 130.266 185.145 128.659C184.467 127.053 184.452 125.455 185.098 123.867C185.744 122.278 186.906 121.13 188.582 120.423C190.258 119.716 191.89 119.686 193.479 120.332C195.068 120.979 196.201 122.105 196.878 123.711C197.556 125.318 197.571 126.915 196.925 128.504ZM190.118 128.454C190.713 128.697 191.326 128.685 191.954 128.42C192.583 128.155 193.002 127.718 193.211 127.109C193.443 126.49 193.432 125.878 193.177 125.272C192.912 124.644 192.481 124.208 191.885 123.966C191.303 123.69 190.698 123.685 190.069 123.95C189.441 124.215 189.01 124.658 188.777 125.277C188.558 125.863 188.581 126.47 188.847 127.098C189.112 127.727 189.535 128.179 190.118 128.454Z" fill="white"/>
    <path opacity="0.7" d="M185.991 30.8458C182.619 32.0652 179.184 31.8773 175.687 30.2823C172.19 28.6873 169.783 26.1813 168.467 22.7645C167.199 19.3699 167.329 15.9969 168.858 12.6457C170.386 9.29441 172.848 6.98481 176.243 5.71687C179.686 4.47108 183.156 4.64571 186.653 6.24074C190.15 7.83578 192.532 10.3306 193.8 13.7253C195.09 17.0714 194.96 20.4443 193.409 23.8442C191.859 27.244 189.386 29.5778 185.991 30.8458ZM159.31 47.2789L209.378 25.4952L212.692 29.9993L162.518 51.8226L159.31 47.2789ZM176.29 20.0842C176.766 21.3572 177.659 22.2928 178.97 22.8909C180.282 23.489 181.574 23.5504 182.847 23.0749C184.142 22.5508 185.089 21.6331 185.687 20.3218C186.285 19.0104 186.346 17.7182 185.871 16.4452C185.395 15.1722 184.502 14.2367 183.191 13.6385C181.879 13.0404 180.587 12.9791 179.314 13.4545C178.09 13.9522 177.178 14.8567 176.58 16.168C175.982 17.4794 175.885 18.7848 176.29 20.0842ZM197.49 70.8545C194.095 72.1225 190.649 71.9589 187.152 70.3639C183.655 68.7688 181.273 66.274 180.005 62.8793C178.737 59.4847 178.867 56.1117 180.396 52.7605C181.925 49.4092 184.386 47.0996 187.781 45.8317C191.176 44.5637 194.621 44.7273 198.118 46.3223C201.615 47.9174 203.998 50.4122 205.266 53.8069C206.534 57.2015 206.403 60.5745 204.875 63.9257C203.346 67.277 200.884 69.5866 197.49 70.8545ZM187.828 60.199C188.304 61.472 189.197 62.4076 190.509 63.0057C191.82 63.6038 193.099 63.6298 194.346 63.0836C195.641 62.5596 196.576 61.6662 197.152 60.4034C197.75 59.092 197.812 57.7998 197.336 56.5268C196.931 55.2274 196.073 54.2786 194.762 53.6805C193.451 53.0824 192.147 53.0453 190.852 53.5694C189.628 54.067 188.717 54.9715 188.118 56.2828C187.52 57.5942 187.424 58.8996 187.828 60.199Z" fill="white"/>
  </svg>
</template>
