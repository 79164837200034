<template>
  <svg preserveAspectRatio="none" viewBox="0 0 190 388" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M104.272 45.9044C104.692 35.7936 103.208 25.8126 98.7068 16.395C94.8315 8.29654 85.2482 3.70403 76.5053 3.69061C48.7523 3.65483 45.2211 40.5024 27.552 54.7138C22.1525 59.0559 16.1182 62.5931 11.0092 67.2751C-7.85333 84.572 0.643745 116.581 17.9642 131.745C29.9836 142.263 48.8774 148.241 63.6904 140.018C71.4231 135.725 77.292 128.588 81.4757 120.767C87.4116 109.672 91.2378 96.865 95.4573 85.0415C99.9539 72.449 103.717 59.3466 104.272 45.9044Z" fill="#6E4600"/>
    <path d="M5.08801 6.56123C6.37084 5.63109 9.61589 12.2449 11.1714 12.1823C12.7313 12.1197 10.0986 2.92564 11.2697 2.21463C13.4286 0.908859 14.774 12.1062 15.8066 11.5651C16.8391 11.0285 16.0211 -0.589195 18.256 0.0234451C20.4864 0.636078 17.8448 12.1599 19.2081 12.0749C20.5759 11.99 20.701 1.1369 23.5527 0.475082C25.0725 0.121803 20.9826 18.9928 22.3548 20.625C23.7315 22.2617 29.587 16.2561 31.0799 17.4366C32.5683 18.6172 19.6104 27.4356 19.4093 28.6206C19.2081 29.8012 16.5262 55.1384 16.8749 63.514C19.7668 63.675 36.1039 66.9126 38.8171 67.0065C37.0202 72.3056 41.4364 77.7791 39.9569 83.1318C30.8027 82.3224 3.56377 80.2475 1.8563 78.2799C-3.34654 72.2922 6.18754 35.306 8.38221 28.8174C10.5814 22.3288 8.43143 19.6367 8.54765 15.6568C8.66386 11.6769 3.80964 7.49136 5.08801 6.56123Z" fill="#F7A491"/>
    <path d="M47.5322 384.885C49.8431 383.181 51.7025 382.059 59.0777 378.481C59.556 378.249 61.3081 377.131 61.9384 376.746C63.5386 375.767 65.1477 374.224 66.5288 372.681C67.8519 371.206 68.4822 366.475 68.7772 362.709C68.0173 354.293 55.8281 219.312 53.258 204.698C50.6029 189.615 54.6481 166.607 54.6481 166.607L64.057 163.057L81.9229 225.005L80.5149 272.155C80.5149 272.155 84.712 285.75 85.2439 293.87C85.7758 301.991 79.1739 367.919 79.1739 367.919C79.1695 370.276 79.2633 372.409 79.5271 373.741C80.0098 376.21 82.7096 381.285 81.4714 383.606C80.2333 385.927 78.8163 386.504 78.8163 386.504L46.7187 387.023C44.971 387.054 46.1242 385.922 47.5322 384.885Z" fill="#F48C7F"/>
    <path d="M96.2547 161.768C96.2547 161.768 107.402 178.596 110.057 192.516C112.713 206.441 108.109 249.228 113.213 267.473C115.975 277.351 126.453 292.604 128.044 305.367C129.635 318.129 135.178 354.968 135.178 354.968C136.018 358.831 137.328 363.616 139.035 365.839C140.077 367.203 141.981 368.924 143.232 371.384C145.181 375.212 144.104 380.913 142.669 384.052C141.932 385.662 142.718 385.658 141.042 386.176C136.067 387.715 130.779 385.126 128.661 380.35C127.481 377.685 126.444 374.125 126.234 369.644C126.015 364.891 125.268 361.117 124.477 358.322L88.8482 270.88L63.0529 195.414L56.4466 156.55L96.2547 161.768Z" fill="#F79C8D"/>
    <path d="M127.699 377.841C135.41 379.249 140.541 374.724 143.21 371.339C145.172 375.175 146.128 380.899 144.685 384.052C143.415 386.829 139.705 386.748 136.782 386.856C133.693 386.972 131.302 385.25 129.769 382.554C129.054 381.293 128.316 379.723 127.699 377.841Z" fill="#BE3BDE"/>
    <path d="M61.9453 376.741C70.4334 378.927 76.7091 377.443 80.2223 376.025C81.1253 378.596 82.3276 381.825 81.6884 383.685C80.9107 385.961 79.8022 386.48 78.8188 386.503C71.0681 386.708 50.4176 386.985 44.504 387.062C43.6771 387.075 43.2212 386.109 43.7531 385.47C45.2192 383.717 61.3105 377.13 61.9453 376.741Z" fill="#BE3BDE"/>
    <path d="M89.028 269.229C88.362 269.274 87.6915 269.318 87.0165 269.359L86.9137 269.363C86.2522 269.403 85.5862 269.444 84.9202 269.475C84.8799 269.479 84.8397 269.479 84.7995 269.484C84.1335 269.52 83.463 269.551 82.7926 269.578L82.6897 269.582C82.0058 269.614 81.322 269.636 80.6381 269.658C73.0305 269.909 63.6304 269.815 57.6006 268.786C54.5254 237.998 53.3811 209.897 52.2458 203.677C49.4835 188.594 54.6506 164.786 54.6506 164.786L54.2349 159.133L55.5579 155.525L96.9529 160.748C96.9529 160.748 108.548 177.571 111.306 191.496C114.068 205.416 109.281 248.207 114.591 266.452C114.591 266.452 102.773 268.294 89.028 269.229Z" fill="#2F4B7D"/>
    <path d="M32.6505 64.7036C32.6505 64.7036 63.6443 61.457 72.7895 64.5202C81.9393 67.5789 88.3624 80.6545 83.4814 87.9704C78.6003 95.2862 34.6083 85.7076 34.6083 85.7076L32.6505 64.7036Z" fill="#EAB054"/>
    <path d="M87.3178 61.8179C87.3178 61.8179 102.649 60.8654 108.715 67.2958C114.78 73.7218 95.2607 124.566 94.1567 132.749C93.0526 140.928 99.7797 162.679 99.7797 162.679C99.7797 162.679 63.7619 169.727 53.4679 163.104C53.4679 163.104 51.5772 103.71 52.6813 97.865C53.7853 92.0204 63.7619 68.8297 65.97 66.4909C68.1736 64.1522 87.3178 61.8179 87.3178 61.8179Z" fill="#F7CC7F"/>
    <path d="M82.2105 41.9017C82.2105 41.9017 82.2909 42.34 82.434 43.0868C83.1178 46.6686 85.7506 58.5591 87.5296 61.8279C89.6751 65.7765 80.1677 78.3735 71.018 76.8352C68.2959 76.3791 73.3199 65.2623 73.0786 64.0817C72.6182 61.828 71.9924 58.7559 71.3488 55.6033C70.9867 53.8235 70.6202 52.0169 70.276 50.3221C69.2971 45.506 68.5015 41.5664 68.5015 41.5664L82.2105 41.9017Z" fill="#F7A491"/>
    <path d="M82.4339 43.0866C82.2909 42.3398 82.2104 41.9016 82.2104 41.9016L68.5015 41.5662C68.5015 41.5662 69.2971 45.5059 70.276 50.322C70.6202 52.0079 70.9822 53.8011 71.3443 55.5719C73.4585 55.2678 75.6711 54.2661 77.8881 52.379C80.3063 50.322 81.8931 47.8491 82.8496 45.2107C82.6798 44.3656 82.5367 43.6411 82.4339 43.0866Z" fill="#F47458"/>
    <path d="M72.9845 11.5997C72.9845 11.5997 79.4434 10.8707 83.4975 15.9686C87.5471 21.0619 91.9141 36.8116 81.8481 45.375C71.7866 53.943 61.8458 44.2481 60.3707 33.9675C58.8957 23.6913 58.3236 11.2285 72.9845 11.5997Z" fill="#F7A491"/>
    <path d="M57.3951 33.8157C58.593 36.0874 60.6089 37.3439 61.8962 36.624C63.1834 35.904 63.2505 33.4803 62.0526 31.2131C60.8502 28.9414 58.8343 27.6848 57.5471 28.4048C56.2598 29.1248 56.1928 31.5485 57.3951 33.8157Z" fill="#F7A491"/>
    <path d="M72.2594 9.91365C72.2594 9.91365 80.8905 24.8942 87.0678 25.5873C93.2451 26.2804 94.0586 34.2402 94.0586 34.2402C94.0586 34.2402 91.8505 15.8969 85.7314 12.3597C79.6211 8.82254 72.2594 9.91365 72.2594 9.91365Z" fill="#A26700"/>
    <path d="M85.4521 29.3532C84.9515 32.0094 85.9617 34.451 87.7094 34.7998C89.4571 35.1486 91.2808 33.2749 91.7859 30.6142C92.2865 27.9535 91.2763 25.5164 89.5286 25.1676C87.7809 24.8188 85.9528 26.6925 85.4521 29.3532Z" fill="#F7A491"/>
    <path d="M76.9616 36.2755L68.1427 37.541C68.1427 37.541 68.8265 40.8635 72.7198 40.4164C77.4712 39.8797 76.9616 36.2755 76.9616 36.2755Z" fill="white"/>
    <path d="M142.24 214.549L141.042 211.607L138.982 213.096L137.578 217.156L138.575 217.966C138.575 217.966 141.351 215.269 142.24 214.549Z" fill="#B75742"/>
    <path d="M122.88 233.008L123.314 221.457C123.635 212.858 128.472 205.435 134.099 204.907C139.727 204.38 146.051 211.423 148.201 220.608L151.089 232.95C151.371 234.162 152.939 234.166 152.64 232.945L149.569 220.563C147.035 210.327 140.013 202.51 133.916 203.132C127.819 203.753 122.451 211.995 121.941 221.502L121.329 233.012C121.266 234.139 122.84 234.144 122.88 233.008Z" fill="#8F3C87"/>
    <path d="M114.022 223.255L116.208 223.201L110.737 298.976L97.3901 299.074L114.022 223.255Z" fill="#F47458"/>
    <path d="M110.737 298.976H190L175.044 223.429L116.208 223.201L110.737 298.976Z" fill="#F7CC7F"/>
    <path d="M152.024 291.611L190 298.976H110.737L152.024 291.611Z" fill="#F47458"/>
    <path d="M128.979 231.366L129.408 219.815C129.73 211.221 134.571 203.797 140.194 203.27C145.821 202.742 152.146 209.785 154.296 218.97L157.184 231.308C157.465 232.524 159.039 232.524 158.735 231.308L155.668 218.921C153.129 208.69 146.107 200.868 140.015 201.494C133.918 202.116 128.545 210.358 128.04 219.865L127.424 231.371C127.365 232.502 128.934 232.506 128.979 231.366Z" fill="#8F3C87"/>
    <path d="M132.162 195.069C132.22 194.828 122.556 153.808 118.654 144.739C114.747 135.67 99.5722 93.5415 99.5722 93.5415L114.971 87.3078C114.971 87.3078 129.748 121.392 135.523 136.296C141.298 151.201 140.967 179.507 141.222 187.261C141.226 187.373 142.071 193.817 142.076 193.929C142.272 194.783 142.456 195.503 142.54 195.642C142.773 196.017 143.448 198.62 143.479 198.843C143.51 199.071 144.087 199.948 144.319 200.328C144.552 200.708 145.088 205.976 145.204 206.826C145.321 207.68 144.941 209.603 144.941 209.603L145.146 213.135C145.298 215.729 142.612 215.04 142.612 215.04C142.675 216.266 142.245 216.905 141.562 217.138C140.873 217.366 140.06 217.169 139.93 217.223C139.796 217.272 139.747 217.656 139.747 217.656C139.818 218.434 138.942 219.034 138.375 219.141C137.807 219.253 136.797 218.877 136.511 218.542C136.225 218.202 136.028 218.251 136.028 218.251C135.456 218.743 133.261 218.721 132.756 218.014C132.246 217.307 131.786 213.578 131.558 213.247C131.33 212.912 130.843 212.339 130.767 211.803C130.695 211.262 128.899 207.447 128.796 206.682C128.693 205.922 129.801 203.351 130.047 201.813C130.298 200.27 131.795 197.877 132.162 197.189C132.3 196.921 132.264 196.04 132.162 195.069Z" fill="#F7A491"/>
    <path d="M108.052 120.506C108.052 120.506 90.7364 94.588 89.1764 85.0631C87.6209 75.5426 96.1896 63.7595 104.933 64.6717C113.68 65.5795 128.627 108.947 128.627 108.947L108.052 120.506Z" fill="#F7CC7F"/>
  </svg>
</template>
