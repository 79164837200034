export default {
  namespaced: true,
  state () {
    return {
      saveText: false,
      isShowSave: false
    }
  },
  mutations: {
    animateSave (state, props) {
      const { text } = props || {}

      if (text) {
        state.saveText = text
      }

      state.isShowSave = true
      setTimeout(() => {
        state.isShowSave = false
        state.saveText = false
      }, 3000)
    },
    closeSave (state) {
      state.isShowSave = false
      state.saveText = false
    }
  }
}
